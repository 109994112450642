import { useMemo } from 'react';
import equal from 'fast-deep-equal';
import { useTranslation } from 'react-i18next';
import { TEMPERATURE_OPTIONS, TEMPERATURE_ENUM } from '~/enums/status';
import { ITemperatureBound } from '~/types/api';

const useTemperatureBound = (temperatureBound?: ITemperatureBound) => {
	const { t } = useTranslation();
	const [temperatureText, color] = useMemo(() => {
		const temperatureData: any = TEMPERATURE_OPTIONS.find((option) =>
			equal(temperatureBound, option.temperatureBound)
		);
		if (!temperatureData) return ['', 'black'];
		const { temperatureBound: bound, label, color, code } = temperatureData;
		const { lower, upper } = bound;
		const unit = t('temperatureBound.unit');
		const interval =
			code !== TEMPERATURE_ENUM.NORMAL_TEMPERATURE
				? `${lower}${unit} ~ ${upper}${unit}`
				: '';
		return [`${label} ${interval}`, color];
	}, [temperatureBound, t]);

	return { temperatureText, temperatureColor: color };
};

export default useTemperatureBound;
