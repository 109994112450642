import styled from 'styled-components';
import {
	PICKUP_STATUS_TEXT,
	PICKUP_STATUS_COLOR,
	DELIVERY_STATUS_TEXT,
	DELIVERY_STATUS_COLOR,
} from '~/enums/status';
import Typography from '~/components/Typography';
interface IChip {
	bgcolor: string;
}
const Chip = styled.div<IChip>`
	text-align: center;
	border-radius: 8px;
	padding: 0 8px;
	color: white;
	height: 20px;
	min-width: 58px;
	background-color: ${({ bgcolor, theme }) => theme.colors[bgcolor]};
`;

interface StatusChipProps {
	status: number;
	isPickup: boolean;
	disabled?: boolean;
}

const StatusChip = ({
	isPickup,
	status,
	disabled = false,
}: StatusChipProps) => {
	const text = isPickup
		? PICKUP_STATUS_TEXT[status]
		: DELIVERY_STATUS_TEXT[status];
	const color = isPickup
		? PICKUP_STATUS_COLOR[status]
		: DELIVERY_STATUS_COLOR[status];

	if (!text) return <></>;
	return (
		<Chip bgcolor={disabled ? 'black20' : color}>
			<Typography variant='body2' color='white'>
				{text}
			</Typography>
		</Chip>
	);
};

StatusChip.defaultProps = {
	isPickup: true,
};
export default StatusChip;
