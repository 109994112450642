import type { RouteObject } from 'react-router-dom';
import HomePage from '~/pages/HomePage';
import NotFoundPage from '~/pages/NotFoundPage';
import RequireAuth from '~/components/RequireAuth';
import LogoutPage from '~/pages/Logout';
import ErrorPage from '~/pages/ErrorPage';
import * as LoginPages from '~/pages/LoginPages';
import * as TaskDetailPages from '~/pages/TaskDetailPages';
import * as InitPages from '~/pages/InitPages';
import LiffScanPage from '~/pages/LiffScanPage';
import ExpiredView from '~/pages/InitPages/components/ExpiredView';
import FileViewer from '~/pages/FileViewer';

const routes: RouteObject[] = [
	{
		path: '/',
		// element: <Layout />,
		children: [
			{
				index: true,
				element: (
					<RequireAuth>
						<HomePage />
					</RequireAuth>
				),
			},
			{
				path: '/task-detail/pallet/:id/:driverType',
				children: [
					{
						index: true,
						element: (
							<RequireAuth>
								<TaskDetailPages.PalletTaskDetailPage />
							</RequireAuth>
						),
					},
				],
			},
			{
				path: '/task-detail/multistop/:id/:driverType',
				children: [
					{
						index: true,
						element: (
							<RequireAuth>
								<TaskDetailPages.MultistopTaskDetailPage />
							</RequireAuth>
						),
					},
				],
			},
			{
				path: '/task-detail/container/:id/:driverType',
				children: [
					{
						index: true,
						element: (
							<RequireAuth>
								<TaskDetailPages.ContainerTaskDetailPage />
							</RequireAuth>
						),
					},
				],
			},
		],
	},
	{
		path: 'temp/task-detail/pallet/:id/:driverType',
		element: <TaskDetailPages.PalletTaskDetailPage isTemporaryDriver />,
	},
	{
		path: 'temp/task-detail/multistop/:id/:driverType',
		element: <TaskDetailPages.MultistopTaskDetailPage isTemporaryDriver />,
	},
	{
		path: 'temp/task-detail/container/:id/:driverType',
		element: <TaskDetailPages.ContainerTaskDetailPage isTemporaryDriver />,
	},
	{
		path: 'temp/task-detail/expired',
		element: <ExpiredView />,
	},
	{
		path: '/login/line',
		element: <LoginPages.LoginLinePage />,
	},
	{
		path: 'login',
		element: <LoginPages.LoginPage />,
	},
	{
		path: 'forgot',
		element: <LoginPages.ForgotPage />,
	},
	{
		path: 'reset-password/:token',
		element: <LoginPages.ResetPage />,
	},
	{
		path: '/initiate/:token',
		element: <InitPages.InitPage />,
	},
	{
		path: '/logout',
		element: <LogoutPage />,
	},
	{
		path: '/error',
		element: <ErrorPage />,
	},
	{
		path: '/fileView/:fileId',
		element: <FileViewer />,
	},
	{
		path: '/liffScan',
		element: <LiffScanPage />,
	},
	{
		path: '*',
		element: (
			<RequireAuth>
				<NotFoundPage />
			</RequireAuth>
		),
	},
];

export default routes;
