export const MAX_FILE_NUMBER = 15;

export enum UPLOAD_FILE_ENUM {
	OTHER = 'OTHER',
	POD = 'POD',
	DOC = 'DOC',
}

export enum AcceptedFileType {
	heic = 'image/heic',
	jpg = 'image/jpg',
	jpeg = 'image/jpeg',
	png = 'image/png',
	pdf = 'application/pdf',
	doc = 'application/msword',
	docx = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
	xls = 'application/vnd.ms-excel',
	xlsx = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
	txt = 'text/plain',
}

export const AcceptedFileTypeMapping = new Map<string, string>(
	Object.entries(AcceptedFileType).map(
		([key, value]) => [`${value}`, key] as const
	)
);

export const imageTypes = [
	AcceptedFileType.heic,
	AcceptedFileType.jpg,
	AcceptedFileType.jpeg,
	AcceptedFileType.png,
];
