import i18next from 'i18next';

export const SHIPMENT_STATUS_CMD_ENUM: any = {
	DISPATCH: 2,
	COMPLETE_PICKUP: 3,
	AT_COLLECTION_STATION: 4,
	IN_TRANSSHIP: 5,
	AT_DISTRIBUTION_STATION: 6,
	IN_TRANSIT: 7,
	DELIVER: 8,
};

export const MULTISTOP_SHIPMENT_CMD: any = {
	DISPATCH: 'DISPATCH',
	COMPLETE_PICKUP: 'COMPLETE_PICKUP',
	IN_TRANSIT: 'IN_TRANSIT',
	UNLOAD: 'UNLOAD',
	DELIVER: 'DELIVER',
	EDIT_PICKUP_DRIVER_NOTE: 'EDIT_PICKUP_DRIVER_NOTE',
	EDIT_DELIVER_DRIVER_NOTE: 'EDIT_DELIVER_DRIVER_NOTE',
};

export const MULTISTOP_SHIPMENT_STATUS: any = {
	DISPATCHED: 2,
	PICK_UP_COMPLETED: 3,
	IN_TRANSIT: 7,
	DELIVERED: 8,
};

export const SHIPMENT_STATUS = {
	CREATED: 0,
	ASSIGNED: 1,
	DISPATCHED: 2,
	PICK_UP_COMPLETED: 3,
	AT_COLLECTION_STATION: 4,
	IN_TRANSSHIP: 5,
	AT_DISTRIBUTION_STATION: 6,
	IN_TRANSIT: 7,
	DELIVERED: 8,
	COMPLETED: 9,
	ABNORMAL: 10,
	REJECTED: 11,
};

export const PICKUP_STATUS: any = {
	ASSIGNED: 1,
	DISPATCHED: 2,
	PICK_UP_COMPLETED: 3,
};

export const DELIVERY_STATUS: any = {
	PICK_UP_COMPLETED: 3,
	AT_COLLECTION_STATION: 4,
	IN_TRANSSHIP: 5,
	AT_DISTRIBUTION_STATION: 6,
	IN_TRANSIT: 7,
	DELIVERED: 8,
};

export const PICKUP_STATUS_TEXT: any = {
	[PICKUP_STATUS.ASSIGNED]: i18next.t('task:pending'),
	[PICKUP_STATUS.DISPATCHED]: i18next.t('task:processing'),
	[PICKUP_STATUS.PICK_UP_COMPLETED]: i18next.t('task:completed'),
};

export const PICKUP_STATUS_COLOR: any = {
	[PICKUP_STATUS.ASSIGNED]: 'black60',
	[PICKUP_STATUS.DISPATCHED]: 'primary',
	[PICKUP_STATUS.PICK_UP_COMPLETED]: 'success',
};

export const DELIVERY_STATUS_TEXT: any = {
	[DELIVERY_STATUS.PICK_UP_COMPLETED]: i18next.t('task:pending'),
	[DELIVERY_STATUS.AT_COLLECTION_STATION]: i18next.t('task:pending'),
	[DELIVERY_STATUS.IN_TRANSSHIP]: i18next.t('task:pending'),
	[DELIVERY_STATUS.AT_DISTRIBUTION_STATION]: i18next.t('task:pending'),
	[DELIVERY_STATUS.IN_TRANSIT]: i18next.t('task:processing'),
	[DELIVERY_STATUS.DELIVERED]: i18next.t('task:completed'),
};

export const DELIVERY_STATUS_COLOR: any = {
	[DELIVERY_STATUS.PICK_UP_COMPLETED]: 'black60',
	[DELIVERY_STATUS.AT_COLLECTION_STATION]: 'black60',
	[DELIVERY_STATUS.IN_TRANSSHIP]: 'black60',
	[DELIVERY_STATUS.AT_DISTRIBUTION_STATION]: 'black60',
	[DELIVERY_STATUS.IN_TRANSIT]: 'primary',
	[DELIVERY_STATUS.DELIVERED]: 'success',
};

export const LIST_PICKUP_STATUS_TEXT: any = {
	[SHIPMENT_STATUS.CREATED]: '',
	[SHIPMENT_STATUS.ASSIGNED]: i18next.t('task:pending'),
	[SHIPMENT_STATUS.DISPATCHED]: i18next.t('task:processing'),
	[SHIPMENT_STATUS.PICK_UP_COMPLETED]: i18next.t('task:completed'),
	[SHIPMENT_STATUS.AT_COLLECTION_STATION]: i18next.t('task:completed'),
	[SHIPMENT_STATUS.IN_TRANSSHIP]: i18next.t('task:completed'),
	[SHIPMENT_STATUS.AT_DISTRIBUTION_STATION]: i18next.t('task:completed'),
	[SHIPMENT_STATUS.IN_TRANSIT]: i18next.t('task:completed'),
	[SHIPMENT_STATUS.DELIVERED]: i18next.t('task:completed'),
	[SHIPMENT_STATUS.COMPLETED]: i18next.t('task:closed'),
	[SHIPMENT_STATUS.ABNORMAL]: i18next.t('task:closed'),
	[SHIPMENT_STATUS.REJECTED]: '',
};

export const LIST_PICKUP_STATUS_COLOR: any = {
	[SHIPMENT_STATUS.CREATED]: 'black60',
	[SHIPMENT_STATUS.ASSIGNED]: 'black60',
	[SHIPMENT_STATUS.DISPATCHED]: 'primary',
	[SHIPMENT_STATUS.PICK_UP_COMPLETED]: 'black20',
	[SHIPMENT_STATUS.AT_COLLECTION_STATION]: 'black20',
	[SHIPMENT_STATUS.IN_TRANSSHIP]: 'black20',
	[SHIPMENT_STATUS.AT_DISTRIBUTION_STATION]: 'black20',
	[SHIPMENT_STATUS.IN_TRANSIT]: 'black20',
	[SHIPMENT_STATUS.DELIVERED]: 'black20',
	[SHIPMENT_STATUS.COMPLETED]: 'black20',
	[SHIPMENT_STATUS.ABNORMAL]: 'black20',
	[SHIPMENT_STATUS.REJECTED]: 'black20',
};

export const LIST_DELIVERY_STATUS_TEXT: any = {
	[SHIPMENT_STATUS.CREATED]: '',
	[SHIPMENT_STATUS.ASSIGNED]: i18next.t('task:notStarted'),
	[SHIPMENT_STATUS.DISPATCHED]: i18next.t('task:notStarted'),
	[SHIPMENT_STATUS.PICK_UP_COMPLETED]: i18next.t('task:pending'),
	[SHIPMENT_STATUS.AT_COLLECTION_STATION]: i18next.t('task:pending'),
	[SHIPMENT_STATUS.IN_TRANSSHIP]: i18next.t('task:pending'),
	[SHIPMENT_STATUS.AT_DISTRIBUTION_STATION]: i18next.t('task:pending'),
	[SHIPMENT_STATUS.IN_TRANSIT]: i18next.t('task:processing'),
	[SHIPMENT_STATUS.DELIVERED]: i18next.t('task:completed'),
	[SHIPMENT_STATUS.COMPLETED]: i18next.t('task:closed'),
	[SHIPMENT_STATUS.ABNORMAL]: i18next.t('task:closed'),
	[SHIPMENT_STATUS.REJECTED]: '',
};

export const LIST_DELIVERY_STATUS_COLOR: any = {
	[SHIPMENT_STATUS.CREATED]: 'black40',
	[SHIPMENT_STATUS.ASSIGNED]: 'black40',
	[SHIPMENT_STATUS.DISPATCHED]: 'black40',
	[SHIPMENT_STATUS.PICK_UP_COMPLETED]: 'black60',
	[SHIPMENT_STATUS.AT_COLLECTION_STATION]: 'black60',
	[SHIPMENT_STATUS.IN_TRANSSHIP]: 'black60',
	[SHIPMENT_STATUS.AT_DISTRIBUTION_STATION]: 'black60',
	[SHIPMENT_STATUS.IN_TRANSIT]: 'primary',
	[SHIPMENT_STATUS.DELIVERED]: 'black20',
	[SHIPMENT_STATUS.COMPLETED]: 'black20',
	[SHIPMENT_STATUS.ABNORMAL]: 'black20',
	[SHIPMENT_STATUS.REJECTED]: 'black20',
};

export enum TEMPERATURE_ENUM {
	FREEZE = 0,
	REFRIGERATION = 1,
	NORMAL_TEMPERATURE = 2,
	REFRIGERATION_OLD = 3,
}

export const TEMPERATURE_TEXT: any = {
	[TEMPERATURE_ENUM.FREEZE]: i18next.t('temperatureBound.freeze'),
	[TEMPERATURE_ENUM.REFRIGERATION]: i18next.t('temperatureBound.refrigeration'),
	[TEMPERATURE_ENUM.NORMAL_TEMPERATURE]: i18next.t(
		'temperatureBound.normalTemperature'
	),
	[TEMPERATURE_ENUM.REFRIGERATION_OLD]: i18next.t(
		'temperatureBound.refrigeration'
	),
};

export const TEMPERATURE_OPTIONS = [
	{
		label: TEMPERATURE_TEXT[TEMPERATURE_ENUM.FREEZE],
		code: TEMPERATURE_ENUM.FREEZE,
		temperatureBound: {
			lower: '-20',
			upper: '-18',
		},
		color: '#2454FF',
	},
	{
		label: TEMPERATURE_TEXT[TEMPERATURE_ENUM.REFRIGERATION],
		code: TEMPERATURE_ENUM.REFRIGERATION,
		temperatureBound: {
			lower: '0',
			upper: '7',
		},
		color: '#95ACFF',
	},
	{
		label: TEMPERATURE_TEXT[TEMPERATURE_ENUM.NORMAL_TEMPERATURE],
		code: TEMPERATURE_ENUM.NORMAL_TEMPERATURE,
		temperatureBound: {
			lower: '-100',
			upper: '100',
		},
		color: '#7A8199',
	},
	{
		label: TEMPERATURE_TEXT[TEMPERATURE_ENUM.REFRIGERATION_OLD],
		code: TEMPERATURE_ENUM.REFRIGERATION_OLD,
		temperatureBound: {
			lower: '16',
			upper: '20',
		},
		color: '#95ACFF',
	},
];

export enum DRIVER_USER_STATUS {
	ACTIVATED = 'ACTIVATED',
	ACCOUNT_REGISTER_PENDING = 'ACCOUNT_REGISTER_PENDING',
	PASSWORD_LOCKED = 'PASSWORD_LOCKED',
	AUTHENTICATION_FAILED = 'AUTHENTICATION_FAILED',
}

export enum TASK_STATUS {
	NOT_STARTED = 'NOT_STARTED',
	PENDING = 'PENDING',
	PROCESSING = 'PROCESSING',
	COMPLETED = 'COMPLETED',
	CLOSED = 'CLOSED',
}

export const TASK_STATUS_TEXT: any = {
	[TASK_STATUS.NOT_STARTED]: i18next.t('task:notStarted'),
	[TASK_STATUS.PENDING]: i18next.t('task:pending'),
	[TASK_STATUS.PROCESSING]: i18next.t('task:processing'),
	[TASK_STATUS.COMPLETED]: i18next.t('task:completed'),
	[TASK_STATUS.CLOSED]: i18next.t('task:completed'),
};

export const LIST_TASK_STATUS_COLOR: any = {
	[TASK_STATUS.NOT_STARTED]: 'black40',
	[TASK_STATUS.PENDING]: 'black60',
	[TASK_STATUS.PROCESSING]: 'primary',
	[TASK_STATUS.COMPLETED]: 'black20',
	[TASK_STATUS.CLOSED]: 'black20',
};
