import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import Container from './Container';

const Title = styled.div`
	flex: 1;
	display: flex;
	align-items: center;
	margin-bottom: 130px;
	${(props) => props.theme.styles.body2}
`;

const ExpiredView = () => {
	const { t } = useTranslation();
	return (
		<Container>
			<Title>{t('init:expiredTitle')}</Title>
		</Container>
	);
};

export default ExpiredView;
