import { useMemo } from 'react';
import styled from 'styled-components';
import Box from './Box';

interface IStep {
	active: boolean;
	isLast: boolean;
}

const Step = styled.div<IStep>`
	width: 24px;
	height: 4px;
	border-radius: 4px;
	background-color: ${({ theme, active }) =>
		active ? theme.colors.primary : theme.colors.divider};
	margin-right: ${({ isLast }) => (isLast ? '0px' : '4px')};
`;

const Stepper = ({
	step,
	totalStep = 4,
}: {
	step: number;
	totalStep?: number;
}) => {
	const stepKeyArray = useMemo(() => {
		let i = 0;
		let result = [];
		while (i < totalStep) {
			result.push(i);
			i++;
		}
		return result;
	}, [totalStep]);

	return (
		<Box>
			{stepKeyArray.map((value: number, index) => {
				const isLast = totalStep === value + 1;
				return <Step key={index} active={value <= step} isLast={isLast} />;
			})}
		</Box>
	);
};

Stepper.defaultProp = {
	step: 0,
};

export default Stepper;
