import { useCallback } from 'react';
import useSWR, { useSWRConfig } from 'swr';

interface UseSWRStateResponse<Data> {
	data: Data;
	trigger: (data: Data) => void;
}

function useSWRState<Data>(key: string): UseSWRStateResponse<Data> {
	const { mutate } = useSWRConfig();
	const { data } = useSWR(key, null, {
		revalidateOnMount: false,
	});
	const trigger = useCallback(
		(data) => {
			mutate(key, data, false);
		},
		[key, mutate]
	);

	return { data, trigger };
}

export default useSWRState;
