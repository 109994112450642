import { useRef, useEffect, useMemo, useState, useCallback } from 'react';
import store from 'store2';
import useSWR, { useSWRConfig } from 'swr';
import styled from 'styled-components';
import useMountedState from 'react-use/lib/useMountedState';
import useSWRState from '~/hooks/useSWRState';
import { Typography, Icon, Box } from '~/components';
import Container from '~/components/Layout/Container';
import GotoTopBox from './components/GotoTopButton';
import TaskList from './components/TaskList';
import EmptyListView from './components/EmptyListView';
import RefreshToast, { IRefreshToastData } from './components/RefreshToast';
import useFormatList from './hooks/useFormatList';
import * as GoToTopIcon from '~/static/icons/GoToTop.png';
import * as HeaderLogo from '~/static/icons/HeaderLogo.png';
import * as RefreshIcon from '~/static/icons/RefreshIcon.png';
import * as QuestionMark from '~/static/icons/QuestionMark.png';
import * as Logout from '~/static/icons/logout.png';
import { REFRESH_TOKEN_EXPIRED } from '~/enums/errorMessages';
import { IListTaskItem } from '~/types/apis/task';

const HeaderTitleText = styled(Typography)`
	margin-top: 4px;
`;
const BottomSpace = styled(Box)`
	margin-bottom: 8px;
`;
const HomePage = () => {
	const isMounted = useMountedState();
	const { data: userInfo } = useSWR<any>('/me/driver-user');
	const [showGoToTop, setShowGoToTop] = useState(true);
	const { trigger: setIsLogin } = useSWRState('isLogin');
	const [refreshToastData, setRefreshToast] =
		useState<IRefreshToastData | null>(null);
	const [updateTime, setUpdateTime] = useState<number | undefined>();
	const { onError: configOnError } = useSWRConfig();

	const getListConfig = {
		refreshInterval: 30 * 60 * 1000,
		errorRetryInterval: 10 * 1000,
		refreshWhenHidden: true,
		refreshWhenOffline: true,
		onError: (err: any, key: any, config: any) => {
			if (err?.message === REFRESH_TOKEN_EXPIRED) {
				return configOnError(err, key, config as any);
			} else {
				setRefreshToast({
					type: 'error',
				});
			}
		},
		onSuccess: () => {
			setRefreshToast({
				type: 'success',
			});
			setUpdateTime(+new Date() / 1000);
			setTimeout(() => {
				if (isMounted()) {
					setRefreshToast(null);
				}
			}, 3000);
		},
	};

	const {
		data: palletShipments,
		mutate: refreshShipments,
		isValidating: palletShipmentLoading,
	} = useSWR<{
		data: IListTaskItem[];
		total: number;
	}>('/view/shipments/driver-user', getListConfig);
	const {
		data: multistopShipments,
		mutate: refreshMultistopShipments,
		isValidating: multistopShipmentLoading,
	} = useSWR<{ data: IListTaskItem[]; total: number }>(
		'/view/multistop-shipments/driver-user',
		getListConfig
	);
	const {
		data: containerShipments,
		mutate: refreshContainerShipments,
		isValidating: containerShipmentLoading,
	} = useSWR('/view/container-shipments/driver-user', getListConfig);

	const loading =
		palletShipmentLoading ||
		multistopShipmentLoading ||
		containerShipmentLoading;

	const refresh = useCallback(() => {
		refreshShipments();
		refreshMultistopShipments();
		refreshContainerShipments();
	}, [refreshShipments, refreshMultistopShipments, refreshContainerShipments]);

	const { palletData, multistopData, containerData } = useMemo(() => {
		return {
			palletData: palletShipments?.data ?? [],
			multistopData: multistopShipments?.data ?? [],
			containerData: containerShipments?.data ?? [],
		};
	}, [
		palletShipments?.data,
		multistopShipments?.data,
		containerShipments?.data,
	]);

	const taskList = useFormatList({ palletData, multistopData, containerData });

	const scrollingTimeout = useRef<any>(() => {});

	const onScroll = useCallback(() => {
		clearTimeout(scrollingTimeout.current);
		scrollingTimeout.current = setTimeout(() => {
			if (isMounted()) {
				setShowGoToTop(window.scrollY > 0);
			}
		}, 150);
		if (showGoToTop) {
			setShowGoToTop(false);
		}
	}, [isMounted, showGoToTop]);

	useEffect(() => {
		window.addEventListener('scroll', onScroll);
		return () => {
			window.removeEventListener('scroll', onScroll);
		};
	}, [onScroll]);

	const isEmpty = useMemo(() => taskList?.length <= 0, [taskList]);
	return (
		<Container
			title={
				<Box direction='column' justifyContent='space-between'>
					<Icon
						onClick={() => refresh()}
						url={HeaderLogo}
						width='100px'
						height='16px'
					/>
					<HeaderTitleText variant='label' color='white'>
						{userInfo?.companyName ?? ''}
					</HeaderTitleText>
				</Box>
			}
			leftElement={
				<>
					<Icon
						width='28'
						height='28'
						url={Logout}
						style={{ marginRight: 16 }}
						onClick={() => {
							store.remove('token');
							store.remove('refreshToken');
							setIsLogin(false);
							window.location.href = '/';
						}}
					/>
					<Icon
						width='20'
						height='20'
						url={QuestionMark}
						onClick={() => {
							window.open(
								'https://allytransport.notion.site/4664f91b20c044b2bf2b8391ce2067c4',
								'_blank'
							);
						}}
					/>
				</>
			}
			rightElement={<Icon isLoading={loading} url={RefreshIcon} />}
			rightElementClick={() => {
				refresh();
			}}
		>
			<>
				{refreshToastData && (
					<RefreshToast data={refreshToastData} updateTime={updateTime} />
				)}
				{isEmpty ? (
					<EmptyListView />
				) : (
					taskList.map((data, i) => <TaskList key={i} data={data} />)
				)}
				{!isEmpty && showGoToTop && (
					<GotoTopBox>
						<Icon
							onClick={() =>
								window.scrollTo({
									top: 0,
									behavior: 'smooth',
								})
							}
							url={GoToTopIcon}
						/>
					</GotoTopBox>
				)}
				<BottomSpace />
			</>
		</Container>
	);
};

export default HomePage;
