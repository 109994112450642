import styled from 'styled-components';
import {
	LIST_PICKUP_STATUS_TEXT,
	LIST_PICKUP_STATUS_COLOR,
	LIST_DELIVERY_STATUS_TEXT,
	LIST_DELIVERY_STATUS_COLOR,
} from '~/enums/status';
import Typography from '~/components/Typography';

export const StyledChip = styled.div<{
	bgcolor: string;
}>`
	text-align: center;
	border-radius: 8px;
	padding: 0 8px;
	color: white;
	height: 20px;
	min-width: 58px;
	background-color: ${({ bgcolor, theme }) => theme.colors[bgcolor]};
`;

interface ListStatusChipProps {
	status: number;
	isPickup: boolean;
	disabled?: boolean;
}

const ListStatusChip = ({
	isPickup,
	status,
	disabled = false,
}: ListStatusChipProps) => {
	const text = isPickup
		? LIST_PICKUP_STATUS_TEXT[status]
		: LIST_DELIVERY_STATUS_TEXT[status];
	const color = isPickup
		? LIST_PICKUP_STATUS_COLOR[status]
		: LIST_DELIVERY_STATUS_COLOR[status];

	if (!text) return <></>;
	return (
		<StyledChip bgcolor={disabled ? 'black20' : color}>
			<Typography variant='body2' color='white'>
				{text}
			</Typography>
		</StyledChip>
	);
};

ListStatusChip.defaultProps = {
	isPickup: true,
};
export default ListStatusChip;
