import { useMemo } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import useTimeWindow from '~/hooks/useTimeWindow';
import useTemperatureBound from '~/hooks/useTemperatureBound';
import Box from '~/components/Box';
import Typography from '~/components/Typography';
import { IFormattedTask } from '../../hooks/useFormatList';
import ChipInfo from '../ChipInfo';
import ListStatusChip from '../ListStatusChip';
import StyledCard from './StyledCard';
import StyledTemperatureText from './StyledTemperatureText';
import StyledEllipsisText from './StyledEllipsisText';
import AddressInfo from './AddressInfo';

const TaskCard = ({
	data,
	taskCompleted,
	nextPath,
}: {
	data: IFormattedTask;
	taskCompleted: boolean;
	nextPath: string;
}) => {
	const {
		temperatureBound,
		driverType,
		status,
		pickupInfo,
		deliverInfo,
		deliverInfos,
		id,
		route,
	} = data;
	const navigate = useNavigate();
	const { temperatureText, temperatureColor } =
		useTemperatureBound(temperatureBound);
	const isMultistop = !!deliverInfos;
	let addressData;
	let otherAddressData;
	if (isMultistop) {
		addressData = driverType === 'PICKUP' ? pickupInfo : deliverInfos[0];
		otherAddressData = driverType === 'PICKUP' ? deliverInfos[0] : pickupInfo;
	} else {
		addressData = driverType === 'PICKUP' ? pickupInfo : deliverInfo;
		otherAddressData = driverType === 'PICKUP' ? deliverInfo : pickupInfo;
	}
	return (
		<StyledCard
			color={temperatureColor}
			onClick={() => navigate(`${nextPath}/${id}/${driverType}`)}
		>
			<Box justifyContent='space-between'>
				<Box>
					<ChipInfo
						taskCompleted={taskCompleted}
						temperatureColor={temperatureColor}
						driverType={driverType}
						status={status.code}
					/>
					{temperatureBound && (
						<StyledTemperatureText variant='buttonM'>
							{temperatureText}
						</StyledTemperatureText>
					)}
				</Box>
				<ListStatusChip
					isPickup={driverType === 'PICKUP'}
					status={status.code}
				/>
			</Box>
			{isMultistop ? (
				<MiltistopAddressInfo
					data={addressData}
					stopCount={data?.stopCount}
					taskCompleted={taskCompleted}
					isDeliver={driverType === 'DELIVER'}
					route={route}
				/>
			) : (
				<AddressInfo
					data={addressData}
					otherAddressData={otherAddressData}
					taskCompleted={taskCompleted}
				/>
			)}

			<StatusInfo
				status={status.code}
				driverType={driverType}
				taskCompleted={taskCompleted}
			/>
		</StyledCard>
	);
};

const MiltistopAddressInfo = ({
	data: { station, timeWindow },
	taskCompleted,
	isDeliver,
	stopCount,
	route,
}: any) => {
	const { t } = useTranslation();
	const timeText = useTimeWindow(timeWindow, false);

	return (
		<Box
			alignItems='start'
			style={{ marginTop: '20px', flexDirection: 'column' }}
		>
			<StyledEllipsisText
				variant='h6'
				color={taskCompleted ? 'black20' : 'primary'}
			>
				{route}
			</StyledEllipsisText>
			{!taskCompleted && (
				<>
					<StyledEllipsisText variant='subtitle2'>
						{station?.name}
					</StyledEllipsisText>
					<StyledContentText variant='h6'>
						{stopCount && isDeliver
							? `${t('task:stopCount')}：${stopCount}`
							: timeText}
					</StyledContentText>
				</>
			)}
		</Box>
	);
};

const StatusInfo = ({ status, driverType, taskCompleted }: any) => {
	const { t } = useTranslation();
	const showPickupUncompletedTip = useMemo(
		() => driverType === 'DELIVER' && status < 3,
		[status, driverType]
	);
	return (
		<>
			{!taskCompleted && showPickupUncompletedTip && (
				<StyledAlert justifyContent='start'>
					<StyledAlertText variant='buttonL'>
						{t('task:pickupUncompletedTip', { taskName: t('task:pickup') })}
					</StyledAlertText>
				</StyledAlert>
			)}
		</>
	);
};

const StyledContentText = styled(Typography)`
	margin-top: 8px;
	color: ${({ theme }) => theme.colors.black60};
`;

const StyledAlert = styled(Box)`
	margin-top: 8px;
	height: 40px;
	background: ${({ theme }) => theme.colors.error10};
	width: 100%;
	border-radius: 4px;
	padding: 0 10px;
`;

const StyledAlertText = styled(Typography)`
	color: ${({ theme }) => theme.colors.error};
`;

export default TaskCard;
