import { format, fromUnixTime } from 'date-fns';
import i18next from 'i18next';
import { ITimeWindow } from '~/types/api';
import { NODATA_TEXT } from '~/enums/view';

type timestamp = number;

export const toTime = (t: timestamp) => format(fromUnixTime(t), 'HH:mm');
export const toDate = (t: timestamp, pattern: string = 'yyyy/MM/dd') =>
	format(fromUnixTime(t), pattern);
export const toDateFormat = (t: timestamp, formatString: string) =>
	format(fromUnixTime(t), formatString);

export const toDateTime = (t: timestamp) =>
	format(fromUnixTime(t), 'MM/dd HH:mm');
export const toDateTimeSecond = (t: timestamp) =>
	format(fromUnixTime(t), 'yyyy/MM/dd HH:mm:ss');

export const toDateTimePeriod = (start: timestamp, end: timestamp) =>
	format(fromUnixTime(start), 'yyyy/MM/dd HH:mm') +
	'-' +
	format(fromUnixTime(end), 'HH:mm');

export const i18nyyyy = (t: timestamp) =>
	format(fromUnixTime(t), `yyyy ${i18next.t('year')}`);

export const i18nMMdd = (t: timestamp) =>
	format(fromUnixTime(t), `MM ${i18next.t('month')} dd ${i18next.t('day')}`);
export const i18nMMddHHmm = (t: timestamp) =>
	format(
		fromUnixTime(t),
		`MM ${i18next.t('month')} dd ${i18next.t('day')} HH:mm`
	);
export const secondsToHourMinutesText = (
	seconds?: number | string,
	nullText: string = NODATA_TEXT
) => {
	if (!seconds && seconds !== 0) return nullText;
	const s = Number(seconds);
	const h = Math.floor(s / 3600);
	const m = Math.floor((s % 3600) / 60);
	return `${String(h).padStart(2, '0')}:${String(m).padStart(2, '0')}`;
};
export const getDateSecondsFromTimeWindow = (
	timeWindow: ITimeWindow
): { startDate: number; endDate: number } => {
	const { date, start, end } = timeWindow;
	const startDate = Number(date) + Number(start);
	const endDate = Number(date) + Number(end);
	return { startDate, endDate };
};
