import { useRef, useEffect } from 'react';
import styled from 'styled-components';
import type { Location } from 'history';
import { useTranslation } from 'react-i18next';
import * as Sentry from '@sentry/react';
import history from '~/libraries/history';
import { getTokenData } from '~/libraries/jwt';
import Typography from '~/components/Typography';
import Button from '~/components/Button';
import logo from '~/static/loginLogo.svg';

const Container = styled.div`
	${() => `min-height: ${window.innerHeight}px;`}
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding: 20px;
	text-align: center;
`;
const Logo = styled.img`
	width: 200px;
	margin-bottom: 80px;
`;

const FallbackComponent = ({ resetError }: { resetError: Function }) => {
	const { t } = useTranslation();
	return (
		<Container>
			<Logo src={logo} />
			<Typography variant='h5' style={{ marginBottom: '20px' }}>
				{t('internalServerError')}
			</Typography>
			<Typography variant='body2' style={{ marginBottom: '40px' }}>
				{t('internalServerErrorDesc')}
			</Typography>
			<Button
				onClick={() => {
					resetError();
				}}
			>
				{t('back')}
			</Button>
		</Container>
	);
};

const ErrorBoundary = ({ children }: { children: React.ReactNode }) => {
	const locationsRef = useRef<Location[]>([history.location]);
	useEffect(() => {
		history.listen(({ location }) => {
			locationsRef.current.push(location);
			if (locationsRef.current.length > 10) {
				locationsRef.current = locationsRef.current.slice(
					locationsRef.current.length - 10,
					locationsRef.current.length
				);
			}
		});
	}, []);
	return (
		<Sentry.ErrorBoundary
			fallback={({ resetError }) => (
				<FallbackComponent resetError={resetError} />
			)}
			beforeCapture={(scope) => {
				const { user_id } = getTokenData() ?? {};
				if (user_id) {
					scope.setUser({ email: user_id });
				}
			}}
			onReset={() => {
				let path = '/';
				const prevLocation =
					locationsRef.current[locationsRef.current.length - 2];
				if (prevLocation) {
					const { pathname, search, hash } = prevLocation;
					path = `${pathname}${search}${hash}`;
				}
				window.location.href = path;
			}}
		>
			{children}
		</Sentry.ErrorBoundary>
	);
};

export default ErrorBoundary;
