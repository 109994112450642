import { useMemo, useRef, useEffect } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { PICKUP_STATUS, DELIVERY_STATUS, TASK_STATUS } from '~/enums/status';
import { DRIVER_TYPE, getContainerTaskStatus } from '~/enums/container';
import { toDate } from '~/utils/date';
import Box from '~/components/Box';
import Typography from '~/components/Typography';
import { IFormattedTaskGroup } from '../../hooks/useFormatList';

const CONTAINER_PICKUP_DRIVER_TYPES = [
	DRIVER_TYPE.PICKUP_EXPORT_EMPTY,
	DRIVER_TYPE.PICKUP_IMPORT_LADEN,
	DRIVER_TYPE.PICKUP_EXPORT_LADEN,
	DRIVER_TYPE.PICKUP_IMPORT_EMPTY,
];
const COMPLETED_TASK_STATUS: any = [TASK_STATUS.COMPLETED, TASK_STATUS.CLOSED];

const Header = ({
	data: { list, startDateString },
}: {
	data: IFormattedTaskGroup;
}) => {
	const ref = useRef<HTMLInputElement>(null);
	const countData = useMemo(
		() =>
			list.reduce(
				(acc: any, cur: any) => {
					if (cur.isContainer) {
						const isPickup = CONTAINER_PICKUP_DRIVER_TYPES.includes(
							cur.driverType
						);
						const taskStatus = getContainerTaskStatus({
							serviceType: cur.serviceType,
							driverType: cur.driverType,
							statusCode: cur.status.code,
						});
						if (isPickup) {
							acc.pickupCount += 1;
						} else {
							acc.deliverCount += 1;
						}
						if (COMPLETED_TASK_STATUS.includes(taskStatus)) {
							acc.completedCount += 1;
						}
					} else {
						acc.isContainerOnly = false;
						if (cur.driverType === 'PICKUP') {
							acc.pickupCount += 1;
							if (cur.status.code >= PICKUP_STATUS.PICK_UP_COMPLETED) {
								acc.completedCount += 1;
							}
						}
						if (cur.driverType === 'DELIVER') {
							acc.deliverCount += 1;
							if (cur.status.code >= DELIVERY_STATUS.DELIVERED) {
								acc.completedCount += 1;
							}
						}
					}
					return acc;
				},
				{
					pickupCount: 0,
					deliverCount: 0,
					completedCount: 0,
					isContainerOnly: true,
				}
			),
		[list]
	);
	const todayString = toDate(+new Date() / 1000, 'MM/dd');
	const isToday = startDateString === todayString;
	const { t } = useTranslation();
	const { pickupCount, deliverCount, completedCount, isContainerOnly } =
		countData;
	const totalCount = pickupCount + deliverCount;
	const completedText = `${t(
		'task:completed'
	)} ${completedCount}/${totalCount}`;
	const completedProgressWidthText =
		Math.round((completedCount / totalCount) * 10000) / 100.0 + '%';
	const dateInfo = useMemo(
		() => (isToday ? `${t('task:today')}` : startDateString),
		[startDateString, isToday, t]
	);
	useEffect(() => {
		if (isToday) {
			ref.current?.scrollIntoView({ behavior: 'smooth' });
		}
	}, [isToday]);
	return (
		<StyledHeader ref={ref}>
			<Box style={{ justifyContent: 'space-between', flex: 1 }}>
				<Typography
					variant='header'
					color='white'
					style={{ flex: 1, marginRight: '4px' }}
				>
					{isContainerOnly
						? dateInfo
						: `${dateInfo} ${t('pickup')}: ${pickupCount} ${t(
								'deliver'
						  )}: ${deliverCount}`}
				</Typography>
				<CompleteLabel progressWidth={completedProgressWidthText}>
					<CompletedTaskCountText variant='header'>
						{completedText}
					</CompletedTaskCountText>
				</CompleteLabel>
			</Box>
		</StyledHeader>
	);
};

const StyledHeader = styled.div`
	top: 56px;
	position: sticky;
	height: 60px;
	display: flex;
	background-color: #76716f;
	align-items: center;
	z-index: 2;
	padding: 0 20px;
	scroll-margin-top: 16px;
`;

const CompleteLabel = styled.div<{ progressWidth: string }>`
	align-self: start;
	border-radius: 8px;
	height: 24px;
	display: flex;
	align-items: center;
	background-color: #ffffff33;
	padding: 0 8px;
	position: relative;
	&:before {
		content: '';
		left: 0;
		height: 100%;
		position: absolute;
		transition: 1.3s ease-out;
		width: ${({ progressWidth }) => progressWidth};
		background-color: ${({ theme }) => theme.colors.black60};
		${({ progressWidth }) =>
			progressWidth === '100%'
				? 'border-radius: 8px'
				: 'border-radius: 8px 3px 3px 8px;'}
	}
`;

const CompletedTaskCountText = styled(Typography)`
	z-index: 2;
	color: ${({ theme }) => theme.colors.white};
	font-weight: 400;
`;

export default Header;
