import { useMemo } from 'react';
import { startOfDay, getUnixTime, fromUnixTime } from 'date-fns';
import { toDate } from '~/utils/date';
import { groupBy } from '~/utils/format';
import { IListTaskItem, ITimeWindow } from '~/types/apis/task';
import { CONTAINER_INFO_ENUM } from '~/enums/container';

export interface IFormattedTask extends IListTaskItem {
	startDateTime: number;
	startDateString: string;
	isPallet: boolean;
	isMultistop: boolean;
	isContainer: boolean;
}
export interface IFormattedTaskGroup {
	list: IFormattedTask[];
	startDateString: string;
}

interface UseFormatListProps {
	palletData: IListTaskItem[];
	multistopData: IListTaskItem[];
	containerData: IListTaskItem[];
}

export const useFormatList = ({
	palletData,
	multistopData,
	containerData,
}: UseFormatListProps): IFormattedTaskGroup[] => {
	const palletTaskList = useMemo(() => {
		return palletData.map((task: any) => {
			const timeWindow =
				task.driverType === 'PICKUP'
					? task.pickupInfo.timeWindow
					: task.deliverInfo.timeWindow;
			const startDateTime = getStartDateTime(timeWindow);
			return {
				...task,
				startDateTime: startDateTime,
				startDateString: toDate(startDateTime, 'MM/dd'),
				isPallet: true,
				isMultistop: false,
				isContainer: false,
			};
		});
	}, [palletData]);
	const multistopTaskList = useMemo(() => {
		return multistopData.map((task: any) => {
			const timeWindow =
				task.driverType === 'PICKUP'
					? task.pickupInfo.timeWindow
					: task?.deliverInfos[0].timeWindow;
			const startDateTime = getStartDateTime(timeWindow);
			return {
				...task,
				startDateTime: startDateTime,
				startDateString: toDate(startDateTime, 'MM/dd'),
				isPallet: false,
				isMultistop: true,
				isContainer: false,
			};
		});
	}, [multistopData]);
	const containerTaskList = useMemo(() => {
		return containerData.map((task: any) => {
			const timeWindow = task[CONTAINER_INFO_ENUM[task.driverType]].timeWindow;
			const startDateTime = getStartDateTime(timeWindow);
			return {
				...task,
				startDateTime: startDateTime,
				startDateString: toDate(startDateTime, 'MM/dd'),
				isPallet: false,
				isMultistop: false,
				isContainer: true,
			};
		});
	}, [containerData]);
	const taskList = useMemo(() => {
		const tasksWithDate = [
			...palletTaskList,
			...multistopTaskList,
			...containerTaskList,
		];

		const sortedTasksWithDate = tasksWithDate.sort((a: any, b: any) => {
			return a.startDateTime - b.startDateTime;
		});

		const groupTask = groupBy<'startDateString', IListTaskItem[]>(
			sortedTasksWithDate,
			'startDateString'
		);

		const groupTaskList = Object.entries(groupTask).map((kv) => ({
			startDateString: kv[0],
			list: kv[1],
		})) as any;

		return groupTaskList;
	}, [containerTaskList, multistopTaskList, palletTaskList]);
	return taskList;
};

function getStartDateTime({ date, start }: ITimeWindow) {
	const dateTime = fromUnixTime(Number(date));
	return getUnixTime(startOfDay(dateTime)) + Number(start);
}

export default useFormatList;
