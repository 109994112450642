import store from 'store2';
import useSWR, { useSWRConfig } from 'swr';
import { useTranslation } from 'react-i18next';
import useSWRState from '~/hooks/useSWRState';
import useSWRMutation from '~/hooks/useSWRMutation';
import { useNavigate } from 'react-router-dom';
import Button from '~/components/Button';

const LogoutPage = () => {
	const navigate = useNavigate();
	const { t } = useTranslation();
	const { data: userInfo } = useSWR<any>('/me/driver-user');
	const { cache } = useSWRConfig();
	const { trigger: setIsLogin } = useSWRState('isLogin');
	const { trigger } = useSWRMutation(
		{
			url: '/auth/logout/driver-user',
			method: 'post',
		},
		{
			onSuccess: () => {
				(cache as any).clear();
				store.remove('token');
				store.remove('refreshToken');
				setIsLogin(false);
				navigate('/login');
			},
		}
	);

	const handleLogout = () => {
		trigger(
			{ data: { token: store.get('refreshToken') } },
			{
				successMessage: t('ajax:logoutSuccess'),
				failureMessage: t('ajax:logoutFailed'),
			}
		);
	};
	return (
		<div className='App' style={{ padding: '50px' }}>
			<div style={{ marginBottom: '20px' }}>
				<span>車行：</span>
				<span>{userInfo?.companyName || '--'}</span>
			</div>
			<div style={{ marginBottom: '20px' }}>
				<span>姓名：</span>
				<span>{userInfo?.name || '--'}</span>
			</div>
			<Button onClick={() => handleLogout()}>登出</Button>
		</div>
	);
};

export default LogoutPage;
