import { lazy } from 'react';

const LoginPage = lazy(
	() => import('./LoginPage' /* webpackChunkName: 'LoginPage' */)
);

const LoginLinePage = lazy(
	() => import('./LoginLinePage' /* webpackChunkName: 'LoginPage' */)
);

const ForgotPage = lazy(
	() => import('./ForgotPage' /* webpackChunkName: 'ForgotPage' */)
);

const ResetPage = lazy(
	() => import('./ResetPage' /* webpackChunkName: 'ResetPage' */)
);

export { LoginPage, LoginLinePage, ForgotPage, ResetPage };
