import { ComponentPropsWithoutRef } from 'react';
import styled from 'styled-components';

interface ILinkButton {
	variant: string;
	color: string;
}

interface LinkButtonProps extends ComponentPropsWithoutRef<'a'> {
	text: string;
	variant: string;
	color: string;
}

const StyledLinkButton = styled.a<ILinkButton>`
	cursor: pointer;
	text-decoration: none;
	${({ variant, theme }) => (variant ? theme.styles[variant] : '')}
	color: ${({ color, theme }) =>
		color ? theme.colors[color] : theme.colors.primary};
`;

const LinkButton = ({ text, color, variant, ...props }: LinkButtonProps) => {
	return (
		<StyledLinkButton color={color} variant={variant} {...props}>
			{text}
		</StyledLinkButton>
	);
};

LinkButton.defaultProps = {
	color: 'warning',
	variant: 'buttonM',
};

export default LinkButton;
