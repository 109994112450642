import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from '~/components';

interface IChip {
	bgcolor?: string;
}

const Chip = styled(Box)<IChip>`
	border-radius: 8px;
	height: 28px;
	padding: 0 8px;
	background-color: ${({ bgcolor }) => bgcolor};
	color: ${({ theme }) => theme.colors.white};
	margin-right: 8px;
`;

const AlertChip = styled(Chip)`
	background-color: ${({ theme }) => theme.colors.error};
	width: auto;
	padding: 0 8px;
`;

interface IChipInfo {
	driverType: string;
	status: number;
	taskCompleted: boolean;
	showPODAlert?: boolean;
	temperatureColor: string;
}

const ChipInfo = ({
	driverType,
	taskCompleted,
	showPODAlert = false,
	temperatureColor,
}: IChipInfo) => {
	const { t } = useTranslation();
	const driverTypeText = driverType === 'PICKUP' ? t('pickup') : t('deliver');
	const bgcolor = taskCompleted ? 'rgba(35,25,22,0.2)' : temperatureColor;
	return showPODAlert ? (
		<PODAlertChip />
	) : (
		<Chip bgcolor={bgcolor}>{driverTypeText}</Chip>
	);
};

const PODAlertChip = () => {
	const { t } = useTranslation();
	return (
		<AlertChip>
			<Typography variant='body2' color='white'>
				{t('task:lackofPOD')}
			</Typography>
		</AlertChip>
	);
};

export default ChipInfo;
