import { t } from 'i18next';
import { UPLOAD_FILE_ENUM } from '~/enums/file';
import { TASK_DETAIL_MODAL } from '~/pages/TaskDetailPages/enum';

export enum SERVICE_TYPE {
	EXPORT = 'EXPORT',
	IMPORT = 'IMPORT',
};

export enum DRIVER_TYPE {
	PICKUP_EXPORT_EMPTY = 'PICKUP_EXPORT_EMPTY',
	DELIVER_EXPORT_EMPTY = 'DELIVER_EXPORT_EMPTY',
	PICKUP_EXPORT_LADEN = 'PICKUP_EXPORT_LADEN',
	DELIVER_EXPORT_LADEN = 'DELIVER_EXPORT_LADEN',
	PICKUP_IMPORT_LADEN = 'PICKUP_IMPORT_LADEN',
	DELIVER_IMPORT_LADEN = 'DELIVER_IMPORT_LADEN',
	PICKUP_IMPORT_EMPTY = 'PICKUP_IMPORT_EMPTY',
	DELIVER_IMPORT_EMPTY = 'DELIVER_IMPORT_EMPTY',
};

export const SHOW_DROP_SERVICE_ENUM = {
	DELIVER_EXPORT_EMPTY: true,
	DELIVER_IMPORT_LADEN: true,
};

export const DRIVER_TYPE_TEXT: any = {
	[DRIVER_TYPE.PICKUP_EXPORT_EMPTY]: t('task:pickupExportEmpty'),
	[DRIVER_TYPE.DELIVER_EXPORT_EMPTY]: t('task:deliverExportEmpty'),
	[DRIVER_TYPE.PICKUP_EXPORT_LADEN]: t('task:pickupExportLaden'),
	[DRIVER_TYPE.DELIVER_EXPORT_LADEN]: t('task:deliverExportLaden'),
	[DRIVER_TYPE.PICKUP_IMPORT_LADEN]: t('task:pickupImportLaden'),
	[DRIVER_TYPE.DELIVER_IMPORT_LADEN]: t('task:deliverImportLaden'),
	[DRIVER_TYPE.PICKUP_IMPORT_EMPTY]: t('task:pickupImportEmpty'),
	[DRIVER_TYPE.DELIVER_IMPORT_EMPTY]: t('task:deliverImportEmpty'),
};

export const PREV_DRIVER_TYPE: any = {
	[DRIVER_TYPE.DELIVER_EXPORT_EMPTY]: DRIVER_TYPE.PICKUP_EXPORT_EMPTY,
	[DRIVER_TYPE.PICKUP_EXPORT_LADEN]: DRIVER_TYPE.DELIVER_EXPORT_EMPTY,
	[DRIVER_TYPE.DELIVER_EXPORT_LADEN]: DRIVER_TYPE.PICKUP_EXPORT_LADEN,
	[DRIVER_TYPE.DELIVER_IMPORT_LADEN]: DRIVER_TYPE.PICKUP_IMPORT_LADEN,
	[DRIVER_TYPE.PICKUP_IMPORT_EMPTY]: DRIVER_TYPE.DELIVER_IMPORT_LADEN,
	[DRIVER_TYPE.DELIVER_IMPORT_EMPTY]: DRIVER_TYPE.PICKUP_IMPORT_EMPTY,
};

export enum CONTAINER_TABLE_TYPE {
	DRIVER_NOTE = 'DRIVER_NOTE',
	CONTAINER_NO = 'CONTAINER_NO',
	TASK_SERVICE = 'TASK_SERVICE',
};

export const DRIVER_TYPE_COLUMN_INDEX = {
	PICKUP_EXPORT_EMPTY: 1,
	DELIVER_EXPORT_EMPTY: 2,
	PICKUP_EXPORT_LADEN: 3,
	DELIVER_EXPORT_LADEN: 4,
	PICKUP_IMPORT_LADEN: 1,
	DELIVER_IMPORT_LADEN: 2,
	PICKUP_IMPORT_EMPTY: 3,
	DELIVER_IMPORT_EMPTY: 4,
};

export const DRIVER_TYPE_COMPLETED_STEPS: any = {
	[DRIVER_TYPE.PICKUP_EXPORT_EMPTY]: [{
		name: TASK_DETAIL_MODAL.CONTAINER_NO,
	}, {
		name: TASK_DETAIL_MODAL.UPLOAD_FILES,
		fileType: UPLOAD_FILE_ENUM.OTHER,
	}],
	[DRIVER_TYPE.DELIVER_EXPORT_EMPTY]:[
		{
			name: TASK_DETAIL_MODAL.TASK_SERVICE
		},
		{
			name: TASK_DETAIL_MODAL.UPLOAD_FILES,
			fileType: UPLOAD_FILE_ENUM.POD,
		}
	],
	[DRIVER_TYPE.PICKUP_EXPORT_LADEN]: [
		{
			name: TASK_DETAIL_MODAL.TASK_SERVICE
		},
		{
			name: TASK_DETAIL_MODAL.UPLOAD_FILES,
			fileType: UPLOAD_FILE_ENUM.OTHER,
		}
	],
	[DRIVER_TYPE.DELIVER_EXPORT_LADEN]: [{
		name: TASK_DETAIL_MODAL.TASK_SERVICE
	},
	{
		name: TASK_DETAIL_MODAL.UPLOAD_FILES,
		fileType: UPLOAD_FILE_ENUM.OTHER,
	}],
	[DRIVER_TYPE.PICKUP_IMPORT_LADEN]:[
		{
			name: TASK_DETAIL_MODAL.TASK_SERVICE
		},
		{
			name: TASK_DETAIL_MODAL.UPLOAD_FILES,
			fileType: UPLOAD_FILE_ENUM.OTHER,
		}
	],
	[DRIVER_TYPE.DELIVER_IMPORT_LADEN]: [{
		name: TASK_DETAIL_MODAL.TASK_SERVICE
	},
	{
		name: TASK_DETAIL_MODAL.UPLOAD_FILES,
		fileType: UPLOAD_FILE_ENUM.POD,
	}],
	[DRIVER_TYPE.PICKUP_IMPORT_EMPTY]: [{
		name: TASK_DETAIL_MODAL.TASK_SERVICE
	},
	{
		name: TASK_DETAIL_MODAL.UPLOAD_FILES,
		fileType: UPLOAD_FILE_ENUM.OTHER,
	}],
	[DRIVER_TYPE.DELIVER_IMPORT_EMPTY]: [{
		name: TASK_DETAIL_MODAL.TASK_SERVICE
	},
	{
		name: TASK_DETAIL_MODAL.UPLOAD_FILES,
		fileType: UPLOAD_FILE_ENUM.OTHER,
	}],
};

export const CONTAINER_INFO_ENUM: any = {
	[DRIVER_TYPE.PICKUP_EXPORT_EMPTY]: 'pickupInfo',
	[DRIVER_TYPE.DELIVER_EXPORT_EMPTY]: 'deliverWarehouseInfo',
	[DRIVER_TYPE.PICKUP_EXPORT_LADEN]: 'departureWarehouseInfo',
	[DRIVER_TYPE.DELIVER_EXPORT_LADEN]: 'deliverInfo',
	[DRIVER_TYPE.PICKUP_IMPORT_LADEN]: 'pickupInfo',
	[DRIVER_TYPE.DELIVER_IMPORT_LADEN]: 'deliverWarehouseInfo',
	[DRIVER_TYPE.PICKUP_IMPORT_EMPTY]: 'departureWarehouseInfo',
	[DRIVER_TYPE.DELIVER_IMPORT_EMPTY]: 'deliverInfo',
};

export const DRIVER_NOTE_ENUM: any = {
	[DRIVER_TYPE.PICKUP_EXPORT_EMPTY]: 'pickup',
	[DRIVER_TYPE.DELIVER_EXPORT_EMPTY]: 'deliverWarehouse',
	[DRIVER_TYPE.PICKUP_EXPORT_LADEN]: 'departureWarehouse',
	[DRIVER_TYPE.DELIVER_EXPORT_LADEN]: 'deliver',
	[DRIVER_TYPE.PICKUP_IMPORT_LADEN]: 'pickup',
	[DRIVER_TYPE.DELIVER_IMPORT_LADEN]: 'deliverWarehouse',
	[DRIVER_TYPE.PICKUP_IMPORT_EMPTY]: 'departureWarehouse',
	[DRIVER_TYPE.DELIVER_IMPORT_EMPTY]: 'deliver',
};

export const DRIVER_NOTE_CMD_ENUM: any = {
	[DRIVER_TYPE.PICKUP_EXPORT_EMPTY]: 'UPDATE_PICKUP_DRIVER_NOTE',
	[DRIVER_TYPE.DELIVER_EXPORT_EMPTY]: 'UPDATE_DELIVER_WAREHOUSE_DRIVER_NOTE',
	[DRIVER_TYPE.PICKUP_EXPORT_LADEN]: 'UPDATE_DEPARTURE_WAREHOUSE_DRIVER_NOTE',
	[DRIVER_TYPE.DELIVER_EXPORT_LADEN]: 'UPDATE_DELIVER_DRIVER_NOTE',
	[DRIVER_TYPE.PICKUP_IMPORT_LADEN]: 'UPDATE_PICKUP_DRIVER_NOTE',
	[DRIVER_TYPE.DELIVER_IMPORT_LADEN]: 'UPDATE_DELIVER_WAREHOUSE_DRIVER_NOTE',
	[DRIVER_TYPE.PICKUP_IMPORT_EMPTY]: 'UPDATE_DEPARTURE_WAREHOUSE_DRIVER_NOTE',
	[DRIVER_TYPE.DELIVER_IMPORT_EMPTY]: 'UPDATE_DELIVER_DRIVER_NOTE',
};

export const ADDITIONAL_RECORD_ENUM: any = {
	[DRIVER_TYPE.PICKUP_EXPORT_EMPTY]: 'pickupContainer',
	[DRIVER_TYPE.DELIVER_EXPORT_EMPTY]: 'deliverWarehouse',
	[DRIVER_TYPE.PICKUP_EXPORT_LADEN]: 'departureWarehouse',
	[DRIVER_TYPE.DELIVER_EXPORT_LADEN]: 'deliverContainer',
	[DRIVER_TYPE.PICKUP_IMPORT_LADEN]: 'pickupContainer',
	[DRIVER_TYPE.DELIVER_IMPORT_LADEN]: 'deliverWarehouse',
	[DRIVER_TYPE.PICKUP_IMPORT_EMPTY]: 'departureWarehouse',
	[DRIVER_TYPE.DELIVER_IMPORT_EMPTY]: 'deliverContainer',
};

export const ADDITIONAL_RECORD_CMD_ENUM: any = {
	[DRIVER_TYPE.PICKUP_EXPORT_EMPTY]: 'UPDATE_ADDITIONAL_RECORDS_PICKUP_CONTAINER_WAITING_HOUR',
	[DRIVER_TYPE.DELIVER_EXPORT_EMPTY]: 'UPDATE_ADDITIONAL_RECORDS_DELIVER_WAREHOUSE_WAITING_HOUR',
	[DRIVER_TYPE.PICKUP_EXPORT_LADEN]: 'UPDATE_ADDITIONAL_RECORDS_DEPARTURE_WAREHOUSE_WAITING_HOUR',
	[DRIVER_TYPE.DELIVER_EXPORT_LADEN]: 'UPDATE_ADDITIONAL_RECORDS_DELIVER_CONTAINER',
	[DRIVER_TYPE.PICKUP_IMPORT_LADEN]: 'UPDATE_ADDITIONAL_RECORDS_PICKUP_CONTAINER_WAITING_HOUR',
	[DRIVER_TYPE.DELIVER_IMPORT_LADEN]: 'UPDATE_ADDITIONAL_RECORDS_DELIVER_WAREHOUSE_WAITING_HOUR',
	[DRIVER_TYPE.PICKUP_IMPORT_EMPTY]: 'UPDATE_ADDITIONAL_RECORDS_DEPARTURE_WAREHOUSE_WAITING_HOUR',
	[DRIVER_TYPE.DELIVER_IMPORT_EMPTY]: 'UPDATE_ADDITIONAL_RECORDS_DELIVER_CONTAINER',
};

export enum CONTAINER_SHIPMENT_CMD_ENUM {
	HEAD_TO_LADEN_CONTAINER_PICKUP_LOCATION='HEAD_TO_LADEN_CONTAINER_PICKUP_LOCATION',
	PICKUP_COMPLETED_AT_LADEN_CONTAINER_PICKUP_LOCATION='PICKUP_COMPLETED_AT_LADEN_CONTAINER_PICKUP_LOCATION',
	IN_TRANSIT_TO_LADEN_CONTAINER_DELIVER_WAREHOUSE='IN_TRANSIT_TO_LADEN_CONTAINER_DELIVER_WAREHOUSE',
	AT_LADEN_CONTAINER_DELIVER_WAREHOUSE='AT_LADEN_CONTAINER_DELIVER_WAREHOUSE',
	HEAD_TO_EMPTY_CONTAINER_PICKUP_WAREHOUSE='HEAD_TO_EMPTY_CONTAINER_PICKUP_WAREHOUSE',
	PICKUP_COMPLETED_AT_EMPTY_CONTAINER_PICKUP_WAREHOUSE='PICKUP_COMPLETED_AT_EMPTY_CONTAINER_PICKUP_WAREHOUSE',
	IN_TRANSIT_TO_EMPTY_CONTAINER_RETURN_LOCATION='IN_TRANSIT_TO_EMPTY_CONTAINER_RETURN_LOCATION',
	AT_EMPTY_CONTAINER_RETURN_LOCATION='AT_EMPTY_CONTAINER_RETURN_LOCATION',
	UPDATE_CONTAINER_NO_AND_ADDITIONAL_RECORDS_PICKUP_CONTAINER_WAITING_HOUR='UPDATE_CONTAINER_NO_AND_ADDITIONAL_RECORDS_PICKUP_CONTAINER_WAITING_HOUR',
	UPDATE_ADDITIONAL_RECORDS_PICKUP_CONTAINER_WAITING_HOUR='UPDATE_ADDITIONAL_RECORDS_PICKUP_CONTAINER_WAITING_HOUR',
	UPDATE_ADDITIONAL_RECORDS_DELIVER_WAREHOUSE_WAITING_HOUR='UPDATE_ADDITIONAL_RECORDS_DELIVER_WAREHOUSE_WAITING_HOUR',
	UPDATE_ADDITIONAL_RECORDS_DEPARTURE_WAREHOUSE_WAITING_HOUR='UPDATE_ADDITIONAL_RECORDS_DEPARTURE_WAREHOUSE_WAITING_HOUR',
	UPDATE_ADDITIONAL_RECORDS_DELIVER_CONTAINER='UPDATE_ADDITIONAL_RECORDS_DELIVER_CONTAINER',
	HEAD_TO_EMPTY_CONTAINER_PICKUP_LOCATION='HEAD_TO_EMPTY_CONTAINER_PICKUP_LOCATION',
	PICKUP_COMPLETED_AT_EMPTY_CONTAINER_PICKUP_LOCATION='PICKUP_COMPLETED_AT_EMPTY_CONTAINER_PICKUP_LOCATION',
	IN_TRANSIT_TO_EMPTY_CONTAINER_DELIVER_WAREHOUSE='IN_TRANSIT_TO_EMPTY_CONTAINER_DELIVER_WAREHOUSE',
	AT_EMPTY_CONTAINER_DELIVER_WAREHOUSE='AT_EMPTY_CONTAINER_DELIVER_WAREHOUSE',
	HEAD_TO_LADEN_CONTAINER_PICKUP_WAREHOUSE='HEAD_TO_LADEN_CONTAINER_PICKUP_WAREHOUSE',
	PICKUP_COMPLETED_AT_LADEN_CONTAINER_PICKUP_WAREHOUSE='PICKUP_COMPLETED_AT_LADEN_CONTAINER_PICKUP_WAREHOUSE',
	IN_TRANSIT_TO_LADEN_CONTAINER_RETURN_LOCATION='IN_TRANSIT_TO_LADEN_CONTAINER_RETURN_LOCATION',
	AT_LADEN_CONTAINER_RETURN_LOCATION='AT_LADEN_CONTAINER_RETURN_LOCATION',
	REMOVE_PICKUP_AND_DELIVER_WAREHOUSE_DRIVER_AND_CAR='REMOVE_PICKUP_AND_DELIVER_WAREHOUSE_DRIVER_AND_CAR',
	REMOVE_DEPARTURE_WAREHOUSE_AND_DELIVER_DRIVER_AND_CAR='REMOVE_DEPARTURE_WAREHOUSE_AND_DELIVER_DRIVER_AND_CAR'
};

export enum CONTAINER_SHIPMENT_STATUS_ENUM {
	CREATED = 0,
	ASSIGNED = 1,
	HEAD_TO_EMPTY_CONTAINER_PICKUP_LOCATION = 13,
	PICKUP_COMPLETED_AT_EMPTY_CONTAINER_PICKUP_LOCATION = 14,
	IN_TRANSIT_TO_EMPTY_CONTAINER_DELIVER_WAREHOUSE = 15,
	AT_EMPTY_CONTAINER_DELIVER_WAREHOUSE = 16,
	HEAD_TO_LADEN_CONTAINER_PICKUP_WAREHOUSE = 17,
	PICKUP_COMPLETED_AT_LADEN_CONTAINER_PICKUP_WAREHOUSE = 18,
	IN_TRANSIT_TO_LADEN_CONTAINER_RETURN_LOCATION = 19,
	AT_LADEN_CONTAINER_RETURN_LOCATION = 20,
	HEAD_TO_LADEN_CONTAINER_PICKUP_LOCATION = 21,
	PICKUP_COMPLETED_AT_LADEN_CONTAINER_PICKUP_LOCATION = 22,
	IN_TRANSIT_TO_LADEN_CONTAINER_DELIVER_WAREHOUSE = 23,
	AT_LADEN_CONTAINER_DELIVER_WAREHOUSE = 24,
	HEAD_TO_EMPTY_CONTAINER_PICKUP_WAREHOUSE = 25,
	PICKUP_COMPLETED_AT_EMPTY_CONTAINER_PICKUP_WAREHOUSE = 26,
	IN_TRANSIT_TO_EMPTY_CONTAINER_RETURN_LOCATION = 27,
	AT_EMPTY_CONTAINER_RETURN_LOCATION = 28,
	COMPLETED = 9,
	ABNORMAL = 10,
	REJECTED = 11,
	WITHDREW = 12,
};

export const TAG_COLOR: any = {
	completed: 'rgba(35, 25, 22, 0.2)',
	dry: '#7A8199',
	reefer: '#2454FF',
};

// https://www.notion.so/allytransport/WIP-Container-Order-Export-d920702d914a4c22a2189edc015a4190#886de951c4e84cab81958a4f5169c3ac
//[status, 出口領空櫃, 出口送空櫃,     出口收重櫃,     出口交重櫃      ]
export const EXPORT_SHIPMENT_STATUS_TABLE = [
	[0,  '',           '',            '',            ''           ],
	[1,  'PENDING',    'NOT_STARTED', 'NOT_STARTED', 'NOT_STARTED'],
	[13, 'PROCESSING', 'NOT_STARTED', 'NOT_STARTED', 'NOT_STARTED'],
	[14, 'COMPLETED',  'PENDING',     'NOT_STARTED', 'NOT_STARTED'],
	[15, 'COMPLETED',  'PROCESSING',  'NOT_STARTED', 'NOT_STARTED'],
	[16, 'COMPLETED',  'COMPLETED',   'PENDING',     'NOT_STARTED'],
	[17, 'COMPLETED',  'COMPLETED',   'PROCESSING',  'NOT_STARTED'],
	[18, 'COMPLETED',  'COMPLETED',   'COMPLETED',   'PENDING'    ],
	[19, 'COMPLETED',  'COMPLETED',   'COMPLETED',   'PROCESSING' ],
	[20, 'COMPLETED',  'COMPLETED',   'COMPLETED',   'COMPLETED'  ],
	[9,  'CLOSED',     'CLOSED',      'CLOSED',      'CLOSED'     ],
	[10, 'CLOSED',     'CLOSED',      'CLOSED',      'CLOSED'     ],
	[11, '',           '',            '',            ''           ],
	[12, '',           '',            '',            ''           ],
];

// https://www.notion.so/allytransport/WIP-Container-Order-Import-aa9ac293f96640a5b6388df3a73942ff#f89502429f714e32ba2ff4a9b3d00606
//[status, 進口領重櫃, 進口送重櫃,      進口收空櫃,     進口交空櫃     ]
export const IMPORT_SHIPMENT_STATUS_TABLE = [
	[0,  '',           '',            '',            ''           ],
	[1,  'PENDING',    'NOT_STARTED', 'NOT_STARTED', 'NOT_STARTED'],
	[21, 'PROCESSING', 'NOT_STARTED', 'NOT_STARTED', 'NOT_STARTED'],
	[22, 'COMPLETED',  'PENDING',     'NOT_STARTED', 'NOT_STARTED'],
	[23, 'COMPLETED',  'PROCESSING',  'NOT_STARTED', 'NOT_STARTED'],
	[24, 'COMPLETED',  'COMPLETED',   'PENDING',     'NOT_STARTED'],
	[25, 'COMPLETED',  'COMPLETED',   'PROCESSING',  'NOT_STARTED'],
	[26, 'COMPLETED',  'COMPLETED',   'COMPLETED',   'PENDING'    ],
	[27, 'COMPLETED',  'COMPLETED',   'COMPLETED',   'PROCESSING' ],
	[28, 'COMPLETED',  'COMPLETED',   'COMPLETED',   'COMPLETED'  ],
	[9,  'CLOSED',     'CLOSED',      'CLOSED',      'CLOSED'     ],
	[10, 'CLOSED',     'CLOSED',      'CLOSED',      'CLOSED'     ],
	[11, '',           '',            '',            ''           ],
	[12, '',           '',            '',            ''           ],
];

//[status, 出口領空櫃, 出口送空櫃, 出口收重櫃,  出口交重櫃  ]
export const EXPORT_DRIVER_NOTE_TABLE = [
	[0,  '',         '',         '',         ''        ],
	[1,  '',         '',         '',         ''        ],
	[13, 'EDITABLE', '',         '',         ''        ],
	[14, 'EDITABLE', '',         '',         ''        ],
	[15, 'EDITABLE', 'EDITABLE', '',         ''        ],
	[16, 'EDITABLE', 'EDITABLE', '',         ''        ],
	[17, 'EDITABLE', 'EDITABLE', 'EDITABLE', ''        ],
	[18, 'EDITABLE', 'EDITABLE', 'EDITABLE', ''        ],
	[19, 'EDITABLE', 'EDITABLE', 'EDITABLE', 'EDITABLE'],
	[20, 'EDITABLE', 'EDITABLE', 'EDITABLE', 'EDITABLE'],
	[9,  '',         '',         '',         ''        ],
	[10, '',         '',         '',         ''        ],
	[11, '',         '',         '',         ''        ],
];

//[status, 進口領重櫃, 進口送重櫃, 進口收空櫃,  進口交空櫃  ]
export const IMPORT_DRIVER_NOTE_TABLE = [
	[0,  '',         '',         '',         ''        ],
	[1,  '',         '',         '',         ''        ],
	[21, 'EDITABLE', '',         '',         ''        ],
	[22, 'EDITABLE', '',         '',         ''        ],
	[23, 'EDITABLE', 'EDITABLE', '',         ''        ],
	[24, 'EDITABLE', 'EDITABLE', '',         ''        ],
	[25, 'EDITABLE', 'EDITABLE', 'EDITABLE', ''        ],
	[26, 'EDITABLE', 'EDITABLE', 'EDITABLE', ''        ],
	[27, 'EDITABLE', 'EDITABLE', 'EDITABLE', 'EDITABLE'],
	[28, 'EDITABLE', 'EDITABLE', 'EDITABLE', 'EDITABLE'],
	[9,  '',         '',         '',         ''        ],
	[10, '',         '',         '',         ''        ],
	[11, '',         '',         '',         ''        ],
];

//[status, 出口領空櫃, 出口送空櫃, 出口收重櫃,  出口交重櫃  ]
export const EXPORT_TASK_SERVICE_TABLE = [
	[0,  '',         '',         '',         ''        ],
	[1,  '',         '',         '',         ''        ],
	[13, 'EDITABLE', '',         '',         ''        ],
	[14, 'EDITABLE', '',         '',         ''        ],
	[15, 'EDITABLE', 'EDITABLE', '',         ''        ],
	[16, 'EDITABLE', 'EDITABLE', '',         ''        ],
	[17, 'EDITABLE', 'EDITABLE', 'EDITABLE', ''        ],
	[18, 'EDITABLE', 'EDITABLE', 'EDITABLE', ''        ],
	[19, 'EDITABLE', 'EDITABLE', 'EDITABLE', 'EDITABLE'],
	[20, 'EDITABLE', 'EDITABLE', 'EDITABLE', 'EDITABLE'],
	[9,  '',         '',         '',         ''        ],
	[10, '',         '',         '',         ''        ],
	[11, '',         '',         '',         ''        ],
];

//[status, 進口領重櫃, 進口送重櫃, 進口收空櫃,  進口交空櫃  ]
export const IMPORT_TASK_SERVICE_TABLE = [
	[0,  '',         '',         '',         ''        ],
	[1,  '',         '',         '',         ''        ],
	[21, 'EDITABLE', '',         '',         ''        ],
	[22, 'EDITABLE', '',         '',         ''        ],
	[23, 'EDITABLE', 'EDITABLE', '',         ''        ],
	[24, 'EDITABLE', 'EDITABLE', '',         ''        ],
	[25, 'EDITABLE', 'EDITABLE', 'EDITABLE', ''        ],
	[26, 'EDITABLE', 'EDITABLE', 'EDITABLE', ''        ],
	[27, 'EDITABLE', 'EDITABLE', 'EDITABLE', 'EDITABLE'],
	[28, 'EDITABLE', 'EDITABLE', 'EDITABLE', 'EDITABLE'],
	[9,  '',         '',         '',         ''        ],
	[10, '',         '',         '',         ''        ],
	[11, '',         '',         '',         ''        ],
];

//[status, 出口領空櫃, 出口送空櫃, 出口收重櫃, 出口交重櫃]
export const EXPORT_CONTAINER_NO_TABLE = [
	[0,  '',         '', '', ''],
	[1,  '',         '', '', ''],
	[13, 'EDITABLE', '', '', ''],
	[14, 'EDITABLE', '', '', ''],
	[15, 'EDITABLE', '', '', ''],
	[16, 'EDITABLE', '', '', ''],
	[17, 'EDITABLE', '', '', ''],
	[18, 'EDITABLE', '', '', ''],
	[19, 'EDITABLE', '', '', ''],
	[20, 'EDITABLE', '', '', ''],
	[9,  '',         '', '', ''],
	[10, '',         '', '', ''],
	[11, '',         '', '', ''],
];

//[status, 進口領重櫃, 進口送重櫃, 進口收空櫃, 進口交空櫃]
export const IMPORT_CONTAINER_NO_TABLE = [
	[0,  '', '', '', ''],
	[1,  '', '', '', ''],
	[21, '', '', '', ''],
	[22, '', '', '', ''],
	[23, '', '', '', ''],
	[24, '', '', '', ''],
	[25, '', '', '', ''],
	[26, '', '', '', ''],
	[27, '', '', '', ''],
	[28, '', '', '', ''],
	[9,  '', '', '', ''],
	[10, '', '', '', ''],
	[11, '', '', '', ''],
];

interface IContainerTaskProps {
	serviceType: keyof typeof SERVICE_TYPE;
	driverType?: keyof typeof DRIVER_TYPE;
	statusCode: number;
}

export const getContainerTaskStatus = ({
	serviceType,
	driverType,
	statusCode,
}: IContainerTaskProps) => {
	if (!driverType) return '';
	const table =
		serviceType === SERVICE_TYPE.EXPORT
			? EXPORT_SHIPMENT_STATUS_TABLE
			: IMPORT_SHIPMENT_STATUS_TABLE;
	const driverTypeColumnIndex = DRIVER_TYPE_COLUMN_INDEX[driverType];
	const taskStatus = table.find((arr) => arr[0] === statusCode)?.[driverTypeColumnIndex] ?? ''
	return typeof taskStatus === 'number' ? String(taskStatus) : taskStatus;
};

interface IGetContainerStatusCode {
	serviceType: keyof typeof SERVICE_TYPE;
	driverType?: keyof typeof DRIVER_TYPE;
	taskStatus: string;
}

export const getContainerStatusCode = ({
	serviceType,
	driverType,
	taskStatus,
}: IGetContainerStatusCode) => {
	if (!driverType) return null;
	const table =
		serviceType === SERVICE_TYPE.EXPORT
			? EXPORT_SHIPMENT_STATUS_TABLE
			: IMPORT_SHIPMENT_STATUS_TABLE;
	const driverTypeColumnIndex = DRIVER_TYPE_COLUMN_INDEX[driverType];
	const statusCode = table.find((arr) => arr[driverTypeColumnIndex] === taskStatus)?.[0] ?? null
	return statusCode;
};

interface IEditablePermission extends IContainerTaskProps {
	tableType: keyof typeof CONTAINER_TABLE_TYPE;
}

export const getContainerTaskEditablePermission = ({
	tableType,
	serviceType,
	driverType,
	statusCode
}: IEditablePermission) => {
	if (!driverType) return false;
	let table;
	switch (tableType) {
		case CONTAINER_TABLE_TYPE.DRIVER_NOTE:
			table = serviceType === SERVICE_TYPE.EXPORT ? EXPORT_DRIVER_NOTE_TABLE : IMPORT_DRIVER_NOTE_TABLE;
			break;
		case CONTAINER_TABLE_TYPE.CONTAINER_NO:
			table = serviceType === SERVICE_TYPE.EXPORT ? EXPORT_CONTAINER_NO_TABLE : IMPORT_CONTAINER_NO_TABLE;
			break;
		case CONTAINER_TABLE_TYPE.TASK_SERVICE:
			table = serviceType === SERVICE_TYPE.EXPORT ? EXPORT_TASK_SERVICE_TABLE : IMPORT_TASK_SERVICE_TABLE;
			break;
		default:
	}
	if (!table) return false;
	const driverTypeColumnIndex = DRIVER_TYPE_COLUMN_INDEX[driverType];
	const permission = table.find((arr) => arr[0] === statusCode)?.[driverTypeColumnIndex] ?? '';
	return permission === 'EDITABLE';
};
