import styled from 'styled-components';
import Typography from '~/components/Typography';

const StyledEllipsisText = styled(Typography)`
	color: ${({ color, theme }) => theme.colors[color]};
	width: 100%;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
`;

export default StyledEllipsisText;
