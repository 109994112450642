import { useMemo } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Box, Card, Typography, LinkButton, LabelText } from '~/components';
import {
	DRIVER_TYPE,
	SERVICE_TYPE,
	DRIVER_TYPE_TEXT,
	CONTAINER_INFO_ENUM,
	SHOW_DROP_SERVICE_ENUM,
	TAG_COLOR,
} from '~/enums/container';
import useTimeWindow from '~/hooks/useTimeWindow';
import InfoBox from './InfoBox';

interface IInfoCard {
	taskInfo: any;
}

const InfoCard = ({ taskInfo }: IInfoCard) => {
	const { t } = useTranslation();
	const { driverType = '' } = useParams<{ driverType: DRIVER_TYPE }>();
	const { station, timeWindow } = taskInfo[CONTAINER_INFO_ENUM[driverType]];
	const timeWindowText = useTimeWindow(timeWindow);
	const showDropService = useMemo(
		() =>
			taskInfo.dropService && SHOW_DROP_SERVICE_ENUM.hasOwnProperty(driverType),
		[driverType, taskInfo.dropService]
	);
	return (
		<Card
			hasDivider
			title={
				<Box justifyContent='flex-start'>
					<StyledTag tagColor={TAG_COLOR[taskInfo.containerInfo.type]}>
						<Typography variant='body1'>
							{DRIVER_TYPE_TEXT[driverType]}
						</Typography>
					</StyledTag>
					{showDropService && (
						<StyledTag className='drop'>
							<Typography variant='body1'>{t('task:drop')}</Typography>
						</StyledTag>
					)}
				</Box>
			}
		>
			<Typography variant='h6'>{station.name}</Typography>
			<LinkButton
				variant='buttonL'
				text={station.address}
				onClick={() => {
					let position = '_blank';
					if ('standalone' in window.navigator) {
						position = '_top';
					}
					window.open(
						`https://google.com/maps/dir//${station.address}`,
						position
					);
				}}
			/>
			<LabelText
				label={t('task:containerShipper')}
				text={taskInfo.containerShipper}
				style={{ marginTop: '20px' }}
				textStyle={{ paddingBottom: '20px' }}
			/>
			<LabelText
				label={t(`task:time.${driverType}`)}
				text={timeWindowText}
				style={{ marginTop: '20px' }}
				textStyle={{ paddingBottom: '20px' }}
			/>
			<LabelText
				label={t('task:stationContact')}
				text={
					<>
						{station.contact.name}
						<Link
							variant='subtitle1'
							href={`tel:${station.contact.phoneNumber}`}
						>
							{station.contact.phoneNumber}
						</Link>
					</>
				}
				textStyle={{ paddingBottom: '20px' }}
			/>
			{taskInfo.serviceType === SERVICE_TYPE.IMPORT && (
				<LabelText
					label={t('task:customsBroker')}
					text={taskInfo.customsBroker}
					style={{ marginTop: '20px' }}
					textStyle={{ paddingBottom: '20px' }}
				/>
			)}
			<InfoBox containerInfo={taskInfo.containerInfo} />
		</Card>
	);
};

interface ILink {
	variant: string;
}

const Link = styled.a<ILink>`
	${({ variant, theme }) => (variant ? theme.styles[variant] : '')}
	padding-left: 4px;
`;

export const StyledTag = styled.div<{ tagColor?: string }>`
	padding: 3px 8px;
	margin-right: 4px;
	border-radius: 8px;
	background-color: ${({ tagColor }) => tagColor || '#7a8199'};
	& p {
		color: #fff;
	}
	&.drop {
		background-color: rgba(35, 25, 22, 0.4);
	}
	&.alert {
		background-color: ${({ theme }) => theme.colors.error};
	}
`;

export default InfoCard;
