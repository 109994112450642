import { useCallback } from 'react';
import styled from 'styled-components';
import { useImmer } from 'use-immer';
import useEffectOnce from 'react-use/lib/useEffectOnce';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { parseJWT } from '~/libraries/jwt';
import useSWRMutation from '~/hooks/useSWRMutation';
import { RESPONSE_ERROR_DETAIL } from '~/enums/responseData';
import Typography from '~/components/Typography';
import Button from '~/components/Button';
import logo from '~/static/loginLogo.svg';

enum TYPE_ENUM {
	ERROR = 'ERROR',
	INVALID = 'INVALID',
	VALID = 'VALID',
	SUCCESS = 'SUCCESS',
}

interface IsState {
	type?: TYPE_ENUM;
	token?: string;
	thirdPartyAuthId?: string;
	errorMessage?: string;
}

const LiffScanPage = () => {
	const [state, updateState] = useImmer<IsState>({});
	const navigate = useNavigate();
	const { t } = useTranslation();
	const { trigger: bindLineId, isMutating } = useSWRMutation(
		{
			method: 'PUT',
			url: `/line-auth/{third_party_auth_id}/bind`,
		},
		{
			onSuccess: () => {
				updateState((draft) => {
					draft.type = TYPE_ENUM.SUCCESS;
				});
			},
			onError: (err: any) => {
				if (err?.data?.detail === RESPONSE_ERROR_DETAIL.LINE_REPEAT_BINDING) {
					updateState((draft) => {
						draft.errorMessage = t('line:error.repeat.binding');
					});
				} else {
					updateState((draft) => {
						draft.errorMessage = t('line:error.binding');
					});
				}
			},
		}
	);
	const scanQrCode = useCallback(() => {
		window.liff
			.scanCodeV2()
			.then((result: any) => {
				if (!result.value) {
					updateState((draft) => {
						draft.type = TYPE_ENUM.INVALID;
					});
				}
				const tokenData = parseJWT(result.value);
				if (!tokenData || !tokenData.thirdPartyAuthId) {
					updateState((draft) => {
						draft.type = TYPE_ENUM.INVALID;
					});
				} else {
					updateState((draft) => {
						draft.type = TYPE_ENUM.VALID;
						draft.token = result.value;
						draft.thirdPartyAuthId = tokenData.thirdPartyAuthId;
					});
				}
			})
			.catch((error: any) => {
				// https://developers.line.biz/en/reference/liff/#liff-errors
				updateState((draft) => {
					draft.type = TYPE_ENUM.ERROR;
				});
			});
	}, [updateState]);
	useEffectOnce(() => {
		if (!window.liff) navigate('/');
		if (window.liff?.isInClient()) {
			scanQrCode();
		} else {
			navigate('/');
		}
	});
	if (state.type === TYPE_ENUM.SUCCESS) {
		return (
			<Container>
				<Typography variant='body2' style={{ marginBottom: '40px' }}>
					{t('line:binding.success')}
				</Typography>
				<Button
					loading={isMutating}
					onClick={() => {
						navigate('/login/line');
					}}
				>
					{t('line:button.start')}
				</Button>
			</Container>
		);
	}
	if (state.type === TYPE_ENUM.VALID) {
		const profile = window.liff.getDecodedIDToken();
		return (
			<Container>
				{profile?.picture && <Avatar src={profile.picture} />}
				{profile?.name && (
					<Typography variant='h5' style={{ marginBottom: '40px' }}>
						{profile.name}
					</Typography>
				)}
				{state.errorMessage && (
					<Typography
						variant='body2'
						style={{ color: '#cd2a01', marginBottom: '20px' }}
					>
						{state.errorMessage}
					</Typography>
				)}
				<Button
					loading={isMutating}
					onClick={() => {
						bindLineId({
							url: `/line-auth/${state.thirdPartyAuthId}/bind`,
							data: {
								clientId: profile.aud,
								idToken: window.liff.getIDToken(),
								token: state.token,
							},
						});
					}}
				>
					{t('bind')}
				</Button>
			</Container>
		);
	}
	if (state.type === TYPE_ENUM.INVALID) {
		return (
			<Container>
				<Logo src={logo} />
				<Typography variant='body2' style={{ marginBottom: '40px' }}>
					{t('line:scan.token.invalid')}
				</Typography>
				<Button
					style={{ marginBottom: '20px' }}
					onClick={() => {
						scanQrCode();
					}}
				>
					{t('line:open.scan')}
				</Button>
				<Button
					onClick={() => {
						window.liff.closeWindow();
					}}
				>
					{t('close')}
				</Button>
			</Container>
		);
	}
	if (state.type === TYPE_ENUM.ERROR) {
		return (
			<Container>
				<Logo src={logo} />
				<Typography variant='body2' style={{ marginBottom: '40px' }}>
					{t('line:scan.failure')}
				</Typography>
				<Button
					onClick={() => {
						window.liff.closeWindow();
					}}
				>
					{t('close')}
				</Button>
			</Container>
		);
	}
	return null;
};

const Container = styled.div`
	${() => `min-height: ${window.innerHeight}px;`}
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding: 20px 20px 100px 20px;
	text-align: center;
`;
const Logo = styled.img`
	width: 200px;
	margin-bottom: 40px;
`;
const Avatar = styled.img`
	width: 100px;
	heigth: 100px;
	border-radius: 50px;
	margin-bottom: 20px;
`;

export default LiffScanPage;
