import { ReactElement } from 'react';
import { useLocation, Navigate } from 'react-router-dom';
import useSWRState from '~/hooks/useSWRState';

interface RequireAuthProps {
	children: ReactElement;
}

const RequireAuth = ({ children }: RequireAuthProps) => {
	const location = useLocation();
	const isInLineClient = window.liff?.isInClient() ?? false;
	const { data: isLogin } = useSWRState('isLogin');
	if (!isLogin) {
		if (isInLineClient) {
			return <Navigate to='/login/line' state={{ from: location }} />;
		} else {
			return (
				<Navigate to={`/login${location.search}`} state={{ from: location }} />
			);
		}
	}
	return children;
};

export default RequireAuth;
