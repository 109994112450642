import { ReactNode, CSSProperties } from 'react';
import styled from 'styled-components';
import * as CheckIcon from '~/static/icons/Check.png';
import * as CheckedIcon from '~/static/icons/Checked.png';
import Icon from './Icon';
import Typography from './Typography';

const Row = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-start;
`;

interface ICheckBox {
	checked: boolean;
	label: ReactNode;
	style?: CSSProperties;
	checkBoxStyle?: CSSProperties;
}

const CheckBox = ({ checked, label, style, checkBoxStyle }: ICheckBox) => {
	return (
		<Row style={style}>
			<Icon
				width='12px'
				height='12px'
				url={checked ? CheckedIcon : CheckIcon}
				style={{
					marginRight: '8px',
					...checkBoxStyle,
				}}
			/>
			{typeof label === 'string' ? (
				<Typography variant='helper' color='black60' align='start'>
					{label}
				</Typography>
			) : (
				label
			)}
		</Row>
	);
};

export default CheckBox;
