import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { Typography, Icon } from '~/components';
import * as ArrowLeft from '~/static/icons/ArrowLeft.png';

const HeaderBox = styled.div`
	position: fixed;
	top: 0px;
	height: 57px;
	background: ${({ theme }) => theme.colors.primary};
	display: flex;
	width: 100%;
	justify-content: center;
	align-items: center;
	z-index: 10;
`;

const RightBox = styled.div`
	position: absolute;
	right: 0;
	padding: 12px;
`;

const LeftBox = styled.div`
	position: absolute;
	left: 0;
	display: flex;
	align-items: center;
	padding: 12px;
`;

export interface IHeader {
	showLeftElement?: boolean;
	title: React.ReactNode;
	leftElement?: React.ReactNode;
	leftElementClick?: React.MouseEventHandler;
	rightElement?: React.ReactNode;
	rightElementClick?: React.MouseEventHandler;
}

const Header = ({
	showLeftElement = true,
	title,
	leftElement,
	leftElementClick,
	rightElement,
	rightElementClick,
}: IHeader) => {
	return (
		<HeaderBox>
			{showLeftElement && (
				<LeftElement
					leftElement={leftElement}
					leftElementClick={leftElementClick}
				/>
			)}
			{typeof title === 'string' ? (
				<Typography variant='subtitle2' color='white'>
					{title}
				</Typography>
			) : (
				title
			)}
			{rightElement && (
				<RightElement
					rightElement={rightElement}
					rightElementClick={rightElementClick}
				/>
			)}
		</HeaderBox>
	);
};

const LeftElement = ({
	leftElement,
	leftElementClick,
}: {
	leftElement?: React.ReactNode;
	leftElementClick?: React.MouseEventHandler;
}) => {
	const navigate = useNavigate();
	if (leftElement) {
		return <LeftBox onClick={leftElementClick}>{leftElement}</LeftBox>;
	}
	return (
		<LeftBox onClick={() => navigate(-1)}>
			<Icon url={ArrowLeft} />
		</LeftBox>
	);
};

const RightElement = ({
	rightElement,
	rightElementClick,
}: {
	rightElement?: React.ReactNode;
	rightElementClick?: React.MouseEventHandler;
}) => {
	return <RightBox onClick={rightElementClick}>{rightElement}</RightBox>;
};

export default Header;
