import { ReactNode } from 'react';
import { createPortal } from 'react-dom';
import usePortal from '~/hooks/usePortal';

interface PortalProps {
	id?: string;
	children: ReactNode;
}

const Portal = ({ id = 'modal-root', children }: PortalProps) => {
	const target = usePortal(id);
	return createPortal(children, target);
};

export default Portal;
