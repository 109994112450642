import { useMemo } from 'react';
import styled from 'styled-components';
import { toDateTime } from '~/utils/date';
import { Typography } from '~/components';
import isToday from 'date-fns/isToday';
import { useTranslation } from 'react-i18next';

interface IRefreshToast {
	bgcolor: string;
}

const StyledRefreshToast = styled.div<IRefreshToast>`
	align-items: center;
	justify-content: center;
	width: 100%;
	text-align: center;
	background-color: ${({ bgcolor, theme }) => theme.colors[bgcolor]};
`;

export interface IRefreshToastData {
	type: 'error' | 'success';
}

const RefreshToast = ({
	data,
	updateTime,
}: {
	data: IRefreshToastData;
	updateTime?: number;
}) => {
	const { t } = useTranslation();
	const message = useMemo(() => {
		const { type } = data;
		let msg = '';
		if (type === 'error') {
			if (!updateTime) return (msg = t('task:updateFailed'));
			const today = isToday(updateTime * 1000);
			const dateTimeString = toDateTime(updateTime);
			const timeString = dateTimeString.split(' ')[1];
			msg = `${t('task:updateFailed')} ${t('task:updateFailedTime', {
				time: today ? `${t('task:today')} ${timeString}` : dateTimeString,
			})}`;
		}
		if (type === 'success') msg = t('task:updateSuccess');
		return msg;
	}, [data, updateTime, t]);
	return (
		<StyledRefreshToast bgcolor={data.type === 'error' ? 'error' : 'success'}>
			<Typography variant='buttonM' color='white' style={{ padding: '4px 0' }}>
				{message}
			</Typography>
		</StyledRefreshToast>
	);
};

export default RefreshToast;
