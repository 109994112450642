import styled from 'styled-components';
import logo from '~/static/loginLogo.svg';

const Logo = styled.img<{ logoCenter: boolean }>`
	width: 200px;
	${({ logoCenter }) => (logoCenter ? { marginTop: '-125px' } : null)}
`;

const StyledContainer = styled.div<{ logoCenter: boolean }>`
	${() => `min-height: ${window.innerHeight}px;`}
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 54px 28px 0 28px;
	${({ logoCenter }) => (logoCenter ? { justifyContent: 'center' } : null)}
`;

interface ContainerProps {
	logoCenter?: boolean;
	children: React.ReactNode;
}

const Container = ({ logoCenter = false, children }: ContainerProps) => {
	return (
		<StyledContainer logoCenter={logoCenter}>
			<Logo src={logo} logoCenter={logoCenter} />
			{children}
		</StyledContainer>
	);
};

export default Container;
