import React, { useMemo } from 'react';
import store from 'store2';
import { SWRConfiguration, SWRConfig } from 'swr';
import ajax from '~/libraries/ajax';
import {
	REFRESH_TOKEN_EXPIRED,
	TEMPORARY_DRIVER_TOKEN_EXPIRED,
} from '~/enums/errorMessages';

interface Configuration extends Omit<SWRConfiguration, 'provider'> {
	provider?: () => any;
}

const SWRConfigProvider = ({ children }: { children: React.ReactNode }) => {
	const config: Configuration = useMemo(() => {
		return {
			errorRetryCount: 0,
			revalidateOnFocus: false,
			provider: () => new Map<string, any>([['isLogin', store.has('token')]]),
			fetcher: (resource) => ajax(resource).then((res) => res.data),
			onError: (err, key, config) => {
				if (err?.message === REFRESH_TOKEN_EXPIRED) {
					const { mutate } = config as any;
					store.remove('token');
					store.remove('refreshToken');
					mutate('isLogin', false, false);
					window.location.href = '/';
				}
				if (err?.message === TEMPORARY_DRIVER_TOKEN_EXPIRED) {
					window.location.href = '/temp/task-detail/expired';
				}
				return err;
			},
		};
	}, []);
	return <SWRConfig value={config}>{children}</SWRConfig>;
};

export default SWRConfigProvider;
