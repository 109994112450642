import { PICKUP_STATUS, DELIVERY_STATUS } from '~/enums/status';
import { IFormattedTaskGroup } from '../../hooks/useFormatList';
import Header from './Header';
import TaskCard from './TaskCard';
import CompletedTaskCard from './CompletedTaskCard';
import ContainerTaskCard from './ContainerTaskCard';

const TaskList = ({ data }: { data: IFormattedTaskGroup }) => {
	const { list } = data;
	return (
		<>
			<Header data={data} />
			{list.map((item, i) => {
				if (item.isContainer) {
					return <ContainerTaskCard key={i} data={item} />;
				}
				const nextPath = item.isMultistop
					? '/task-detail/multistop'
					: '/task-detail/pallet';

				let taskCompleted = false;
				if (
					item.driverType === 'PICKUP' &&
					item.status.code >= PICKUP_STATUS.PICK_UP_COMPLETED
				)
					taskCompleted = true;
				if (
					item.driverType === 'DELIVER' &&
					item.status.code >= DELIVERY_STATUS.DELIVERED
				)
					taskCompleted = true;
				return taskCompleted ? (
					<CompletedTaskCard
						key={i}
						data={item}
						taskCompleted={taskCompleted}
						nextPath={nextPath}
					/>
				) : (
					<TaskCard
						key={i}
						data={item}
						taskCompleted={taskCompleted}
						nextPath={nextPath}
					/>
				);
			})}
		</>
	);
};

export default TaskList;
