import { useRef, useState, CSSProperties } from 'react';
import { useParams } from 'react-router-dom';
import useEffectOnce from 'react-use/lib/useEffectOnce';
import useUpdateEffect from 'react-use/lib/useUpdateEffect';
import fetchToken from '~/utils/fetchToken';
import { apiURL } from '~/utils/urls';
import loadingIcon from '~/static/loading.svg';

const DELAY = 3000;

const FileViewer = () => {
	const { fileId } = useParams();
	const timerRef = useRef<NodeJS.Timer | undefined>();
	const [url, setUrl] = useState<string>('');
	const [iframeKey, setIframeKey] = useState(() => new Date().getTime());
	const styles = useStyles();
	const onLoad = () => {
		if (timerRef.current !== undefined) {
			clearInterval(timerRef.current);
		}
	};
	useEffectOnce(() => {
		const init = async () => {
			const token = await fetchToken();
			if (token) {
				const queryUrl = `${apiURL}/files/${fileId}?token=${token}`;
				setUrl(
					`https://docs.google.com/gview?url=${encodeURI(
						queryUrl
					)}&embedded=true`
				);
			}
		};
		init();
	});
	useUpdateEffect(() => {
		timerRef.current = setInterval(() => {
			setIframeKey(new Date().getTime());
		}, DELAY);
	}, [url]);
	if (!url) return <></>;
	return (
		<iframe
			key={iframeKey}
			title={fileId}
			src={url}
			frameBorder={0}
			marginHeight={0}
			marginWidth={0}
			height='100%'
			scrolling='auto'
			style={styles.iframe}
			onLoad={onLoad}
		/>
	);
};

const useStyles = () => {
	return {
		iframe: {
			display: 'block',
			width: '100%',
			border: 'none',
			overflowY: 'auto',
			overflowX: 'hidden',
			background: `url(${loadingIcon}) center center no-repeat`,
		} as CSSProperties,
	};
};

export default FileViewer;
