const BLACK = '#231916';
const ERROR = '#CD2A01';
const WARNING = '#2454FF';
const BACKGROUND = '#F5F5F5';
const DIVIDER = '#DFDFDF';
const SUCCESS = '#42A68E';
const WHITE = '#FFFFFF';
const INFO = '#231916';
const GRAY = '#C4C4C4';

const getErrorRGBA = (opacity: number) => `rgba(205, 42, 1, ${opacity})`;

const COLOR_WITH_OPACITY = {
	black8: '#EDEDEC',
	black20: '#BEBCBB',
	black40: '#A7A3A2',
	black50: '#918C8A',
	black60: '#7B7573',
	white20: 'rgba(0, 0, 0, .2)',
	error10: getErrorRGBA(0.1),
};

export {
	BLACK,
	ERROR,
	WARNING,
	BACKGROUND,
	DIVIDER,
	SUCCESS,
	INFO,
	WHITE,
	GRAY,
	COLOR_WITH_OPACITY,
};
