import { useMemo } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
	DRIVER_TYPE,
	DRIVER_TYPE_TEXT,
	CONTAINER_INFO_ENUM,
	SHOW_DROP_SERVICE_ENUM,
	TAG_COLOR,
	PREV_DRIVER_TYPE,
	getContainerTaskStatus,
} from '~/enums/container';
import {
	TASK_STATUS,
	TASK_STATUS_TEXT,
	LIST_TASK_STATUS_COLOR,
} from '~/enums/status';
import Box from '~/components/Box';
import Typography from '~/components/Typography';
import { StyledChip } from '../ListStatusChip';
import StyledCard from './StyledCard';
import { StyledTag } from '~/pages/TaskDetailPages/container/components/InfoCard';
import AddressInfo from './AddressInfo';

const ContainerTaskCard = ({ data }: { data: any }) => {
	const {
		serviceType,
		dropService,
		driverType,
		id,
		uploadFiles: { pod },
	} = data;
	const navigate = useNavigate();
	const { t } = useTranslation();
	const taskStatus = useMemo(() => {
		return getContainerTaskStatus({
			serviceType,
			driverType,
			statusCode: data.status.code,
		});
	}, [data.status.code, driverType, serviceType]);
	const taskCompleted = useMemo(
		() =>
			[TASK_STATUS.COMPLETED, TASK_STATUS.CLOSED].includes(taskStatus as any),
		[taskStatus]
	);
	const showDropService = useMemo(
		() =>
			dropService &&
			SHOW_DROP_SERVICE_ENUM.hasOwnProperty(driverType) &&
			!taskCompleted,
		[dropService, driverType, taskCompleted]
	);
	const showPODAlert = useMemo(
		() =>
			[
				DRIVER_TYPE.DELIVER_EXPORT_EMPTY,
				DRIVER_TYPE.DELIVER_IMPORT_LADEN,
			].includes(driverType) &&
			taskCompleted &&
			pod.length <= 0,
		[driverType, pod.length, taskCompleted]
	);
	const showContainerNoAlert = useMemo(
		() =>
			driverType === DRIVER_TYPE.PICKUP_EXPORT_EMPTY &&
			taskCompleted &&
			!data.containerNo,
		[data.containerNo, driverType, taskCompleted]
	);
	const tagColor = useMemo(() => {
		if (taskCompleted) {
			return TAG_COLOR.completed;
		}
		return TAG_COLOR[data.containerInfo.type];
	}, [taskCompleted, data.containerInfo.type]);
	const addressData = data[CONTAINER_INFO_ENUM[driverType]];
	return (
		<StyledCard
			color={tagColor}
			taskCompleted={taskCompleted}
			onClick={() => navigate(`/task-detail/container/${id}/${driverType}`)}
		>
			<Box justifyContent='space-between'>
				<Box>
					<StyledTag tagColor={tagColor}>
						<Typography variant='body1'>
							{DRIVER_TYPE_TEXT[driverType]}
						</Typography>
					</StyledTag>
					{showDropService && (
						<StyledTag className='drop'>
							<Typography variant='body1'>{t('task:drop')}</Typography>
						</StyledTag>
					)}
					{showPODAlert && (
						<StyledTag className='alert'>
							<Typography variant='body1'>{t('task:lackofPOD')}</Typography>
						</StyledTag>
					)}
					{showContainerNoAlert && (
						<StyledTag className='alert'>
							<Typography variant='body1'>
								{t('task:lackofContainerNo')}
							</Typography>
						</StyledTag>
					)}
				</Box>
				<TaskStatusChip taskStatus={taskStatus} />
			</Box>
			<AddressInfo data={addressData} taskCompleted={taskCompleted} />
			<Tip taskStatus={taskStatus} driverType={driverType} />
		</StyledCard>
	);
};

const TaskStatusChip = ({ taskStatus }: { taskStatus: string }) => {
	return (
		<StyledChip bgcolor={LIST_TASK_STATUS_COLOR[taskStatus]}>
			<Typography variant='body2' color='white'>
				{TASK_STATUS_TEXT[taskStatus]}
			</Typography>
		</StyledChip>
	);
};
const Tip = ({ driverType, taskStatus }: any) => {
	const { t } = useTranslation();
	if (
		taskStatus !== TASK_STATUS.NOT_STARTED ||
		!PREV_DRIVER_TYPE.hasOwnProperty(driverType)
	)
		return null;
	return (
		<StyledAlert justifyContent='start'>
			<StyledAlertText variant='buttonL'>
				{t('task:pickupUncompletedTip', {
					taskName: DRIVER_TYPE_TEXT[PREV_DRIVER_TYPE[driverType]],
				})}
			</StyledAlertText>
		</StyledAlert>
	);
};

const StyledAlert = styled(Box)`
	margin-top: 8px;
	background: ${({ theme }) => theme.colors.error10};
	width: 100%;
	border-radius: 4px;
	padding: 10px;
`;

const StyledAlertText = styled(Typography)`
	color: ${({ theme }) => theme.colors.error};
`;

export default ContainerTaskCard;
