import Header, { IHeader } from './Header';
import styled from 'styled-components';

const StyledContainer = styled.div`
	${() => `min-height: ${window.innerHeight}px;`}
	padding-top: 56px;
	background-color: ${({ theme }) => theme.bg};
`;

interface IContainer extends IHeader {
	children: React.ReactChild;
}

const Container = ({ children, ...props }: IContainer) => {
	return (
		<StyledContainer>
			<Header {...props} />
			{children}
		</StyledContainer>
	);
};

export default Container;
