import React from 'react';
import ReactDOM from 'react-dom';
import store from 'store2';
import i18next from 'i18next';
import { ThemeProvider } from 'styled-components';
import { unstable_HistoryRouter as HistoryRouter } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { SnackbarProvider } from 'notistack';
import { defaultTheme } from '~/theme';
import history from '~/libraries/history';
import ErrorBoundary from '~/pages/ErrorBoundry';
import SWRConfigProvider from './SWRConfigProvider';
import reportWebVitals from './reportWebVitals';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { ResetStyle } from '~/styles/reset';
import './i18n.ts';
import './index.css';

Sentry.init({
	dsn: 'https://47e492a9985744ad8d69e9b0a70c5052@o1323261.ingest.sentry.io/6580892',
	environment: process.env.ENV_TYPE || 'local',
});

//[ATMS-816] https://github.com/facebook/react/issues/11538#issuecomment-417504600
if (typeof Node === 'function' && Node.prototype) {
	const originalRemoveChild = Node.prototype.removeChild;
	(Node as any).prototype.removeChild = function (child: any) {
		if (child.parentNode !== this) {
			if (console) {
				console.error(
					'Cannot remove a child from a different parent',
					child,
					this
				);
			}
			return child;
		}
		return originalRemoveChild.apply(this, arguments as any);
	};

	const originalInsertBefore = (Node as any).prototype.insertBefore;
	(Node as any).prototype.insertBefore = function (
		newNode: any,
		referenceNode: any
	) {
		if (referenceNode && referenceNode.parentNode !== this) {
			if (console) {
				console.error(
					'Cannot insert before a reference node from a different parent',
					referenceNode,
					this
				);
			}
			return newNode;
		}
		return originalInsertBefore.apply(this, arguments);
	};
}

i18next.on('initialized', async () => {
	await window.liff
		?.init({
			liffId:
				process.env.ENV_TYPE === 'production'
					? '2000159672-1jp6qMrn'
					: '2000005456-Q3vJ18bE',
		})
		.then(() => {
			if (window.liff?.isInClient()) {
				store.remove('token');
				store.remove('refreshToken');
			}
		});
	const App = require('./App').default;
	ReactDOM.render(
		<React.StrictMode>
			<ResetStyle />
			<ThemeProvider theme={defaultTheme}>
				<ErrorBoundary>
					<SnackbarProvider
						classes={{ containerRoot: 'snackbarContainerOverWrite' }}
						maxSnack={3}
						autoHideDuration={2000}
						anchorOrigin={{
							vertical: 'top',
							horizontal: 'center',
						}}
					>
						<SWRConfigProvider>
							<HistoryRouter history={history}>
								<App />
							</HistoryRouter>
						</SWRConfigProvider>
					</SnackbarProvider>
				</ErrorBoundary>
			</ThemeProvider>
		</React.StrictMode>,
		document.getElementById('root')
	);
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

serviceWorkerRegistration.register({
	onUpdate: (registration) => {
		if (registration.update) {
			// Check for updates at start.
			registration.update();
			// Check for updates every 10 minutes.
			setInterval(() => {
				registration.update();
			}, 1000 * 60 * 10);
		}

		// ensure the case when the updatefound event was missed is also handled
		if (registration.waiting) {
			registration.waiting.postMessage({ type: 'SKIP_WAITING' });
		}

		// detect Service Worker update available and wait for it to become installed
		registration.addEventListener('updatefound', () => {
			if (registration.installing) {
				// wait until the new Service worker is actually installed (ready to take over)
				registration.installing.addEventListener('statechange', () => {
					if (registration.waiting) {
						// if there's an existing controller (previous Service Worker), show the prompt
						if (navigator.serviceWorker.controller) {
							registration.waiting.postMessage({ type: 'SKIP_WAITING' });
						} else {
							// Service Worker initialized for the first time
						}
					}
				});
			}
		});
	},
});
