import { forwardRef } from 'react';
import styled from 'styled-components';
import * as InfoIcon from '~/static/icons/InfoIcon.png';
import * as ErrorIcon from '~/static/icons/ErrorIcon.png';
import * as WarningIcon from '~/static/icons/WarningIcon.png';
import * as SuccessIcon from '~/static/icons/SuccessIcon.png';
import Icon from './Icon';
import Typography from './Typography';

interface IBar {
	variant: 'success' | 'error' | 'warning' | 'info';
}
interface ISnackBar extends IBar {
	key: any;
	children: string;
}

const Bar = styled.div<IBar>`
	display: flex;
	flex-direction: row;
	padding: 16px;
	border-radius: 8px;
	background-color: ${({ variant, theme }) => `${theme.colors[variant]}`};
`;

const getIconUrl = (variant: string) => {
	switch (variant) {
		case 'success':
			return SuccessIcon;
		case 'info':
			return InfoIcon;
		case 'warning':
			return WarningIcon;
		case 'error':
			return ErrorIcon;
		default:
			return InfoIcon;
	}
};
const Snackbar = forwardRef<HTMLDivElement, ISnackBar>((props, ref) => {
	const { variant, key, children } = props;
	return (
		<Bar variant={variant} ref={ref} key={key}>
			<Icon url={getIconUrl(variant)} />
			<Typography
				variant='subtitle2'
				color='white'
				style={{ paddingLeft: '8px' }}
			>
				{children}
			</Typography>
		</Bar>
	);
});

Snackbar.defaultProps = {
	variant: 'info',
	children: '',
};
export default Snackbar;
