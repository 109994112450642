import { useMemo } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import useTemperatureBound from '~/hooks/useTemperatureBound';
import { SHIPMENT_STATUS } from '~/enums/status';
import Box from '~/components/Box';
import { IFormattedTask } from '../../hooks/useFormatList';
import ListStatusChip from '../ListStatusChip';
import ChipInfo from '../ChipInfo';
import StyledCard from './StyledCard';
import StyledTemperatureText from './StyledTemperatureText';
import StyledEllipsisText from './StyledEllipsisText';

const CompletedTaskCard = ({
	data,
	taskCompleted,
	nextPath,
}: {
	data: IFormattedTask;
	taskCompleted: boolean;
	nextPath: string;
}) => {
	const {
		temperatureBound,
		driverType,
		status,
		id,
		pickupInfo,
		deliverInfo,
		deliverInfos,
		uploadFiles: { pod },
	} = data;
	const navigate = useNavigate();
	const { temperatureText, temperatureColor } =
		useTemperatureBound(temperatureBound);
	const showPODAlert = useMemo(
		() =>
			driverType === 'DELIVER' &&
			pod.length <= 0 &&
			status.code < SHIPMENT_STATUS.COMPLETED,
		[driverType, pod.length, status.code]
	);
	const deliverStationName = deliverInfos
		? deliverInfos[0]?.station?.name
		: deliverInfo?.station?.name;
	return (
		<StyledCompletedCard
			onClick={() => navigate(`${nextPath}/${id}/${driverType}`)}
		>
			<Box justifyContent='space-between'>
				<Box>
					<ChipInfo
						taskCompleted={taskCompleted}
						temperatureColor={temperatureColor}
						driverType={driverType}
						status={status.code}
						showPODAlert={showPODAlert}
					/>
					{temperatureBound && (
						<StyledTemperatureText variant='buttonM'>
							{temperatureText}
						</StyledTemperatureText>
					)}
				</Box>
				<ListStatusChip
					isPickup={driverType === 'PICKUP'}
					status={status.code}
					disabled
				/>
			</Box>
			<Box
				alignItems='start'
				style={{ marginTop: '20px', flexDirection: 'column' }}
			>
				<StyledEllipsisText
					variant='h6'
					color={taskCompleted ? 'black20' : 'primary'}
				>
					{pickupInfo?.station.name}
				</StyledEllipsisText>
				<StyledEllipsisText
					variant='subtitle2'
					color={taskCompleted ? 'black20' : 'primary'}
				>
					&rarr;&nbsp;{deliverStationName}
				</StyledEllipsisText>
			</Box>
		</StyledCompletedCard>
	);
};

const StyledCompletedCard = styled(StyledCard)`
	min-height: 116px;
	&:before {
		background-color: ${({ theme }) => theme.colors.black20};
	}
`;

export default CompletedTaskCard;
