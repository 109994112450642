import styled from 'styled-components';
interface IText {
	variant: string;
	align: string;
	color: string;
}

const StyledText = styled.p<IText>`
	${({ variant, theme }) => (variant ? theme.styles[variant] : '')}
	color: ${({ color, theme }) => (color ? theme.colors[color] : '')};
	text-align: ${({ align }) => align};
	white-space: pre-line;
`;

interface TypographyProps extends IText {
	style?: any;
	children?: React.ReactNode;
}

const Typography = ({ children, ...props }: TypographyProps) => {
	return <StyledText {...props}>{children}</StyledText>;
};

Typography.defaultProps = {
	variant: 'body2',
	align: 'inherit',
	color: 'primary',
};

export default Typography;
