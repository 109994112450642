import styled from 'styled-components';

const StyledCard = styled.div<{ color?: string; taskCompleted?: boolean }>`
	padding: 20px;
	min-height: ${({ taskCompleted }) => (taskCompleted ? 'auto' : '174px')};
	display: flex;
	flex-direction: column;
	border-radius: 10px;
	background-color: ${({ theme }) => theme.colors.white};
	margin: 20px 16px;
	position: relative;
	overflow: hidden;
	&:before {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		height: 100%;
		width: 8px;
		background-color: ${({ color }) => color};
	}
	z-index: 1;
`;

export default StyledCard;
