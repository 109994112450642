export const RESPONSE_ERROR_DETAIL = {
	PROVIDED_TOKEN_IS_NOT_VALID: 'Provided token is not valid',
	RESET_PASSWORD_WHEN_PASSWORD_LOCKED:
		'Send reset password message FAILED in PASSWORD_LOCKED status',
	DRIVER_ACCOUNT_LOCKED: 'Locked',
	LINE_REPEAT_BINDING: 'line user id has already been used in this Carrier',
};

export const RESPONSE_ERROR_STATUS_TEXT = {
	TOO_MANY_REQUESTS: 'TOO MANY REQUESTS',
};
