import { createPortal } from 'react-dom';
import styled from 'styled-components';
import { Transition } from 'react-transition-group';

const modalRoot = document.getElementById('modal-root') as HTMLElement;

const DialogRoot = styled.div`
	position: fixed;
	z-index: 1300;
	inset: 0px;
`;

const Backdrop = styled.div`
	position: fixed;
	display: flex;
	align-items: center;
	justify-content: center;
	inset: 0px;
	background-color: rgba(0, 0, 0, 0.5);
	-webkit-tap-highlight-color: transparent;
	z-index: -1;
`;

const DialogContainer = styled.div`
	width: 100%;
	height: 100%;
	padding: 16px;
	outline: 0px;
	display: flex;
	justify-content: center;
	align-items: center;
	pointer-events: none;
`;

const DialogContent = styled.div`
	width: 100%;
	padding: 20px;
	background: #fff;
	border-radius: 8px;
`;

interface DialogProps {
	children: JSX.Element;
	open: boolean;
	onClose?: Function;
}

const duration = 225;

const defaultStyle = {
	transition: `opacity ${duration}ms ease-in-out`,
	opacity: 0,
};

const transitionStyles: any = {
	entering: { opacity: 1 },
	entered: { opacity: 1 },
	exiting: { opacity: 0 },
	exited: { opacity: 0 },
};

const Dialog = ({ open, onClose, children }: DialogProps) => {
	return createPortal(
		<Transition in={open} timeout={duration}>
			{(state) => (
				<div
					style={{
						...defaultStyle,
						...transitionStyles[state],
					}}
				>
					{open ? (
						<DialogRoot>
							<Backdrop
								onClick={() => {
									onClose && onClose();
								}}
							/>
							<DialogContainer>
								<DialogContent>{children}</DialogContent>
							</DialogContainer>
						</DialogRoot>
					) : null}
				</div>
			)}
		</Transition>,
		modalRoot
	);
};

export default Dialog;
