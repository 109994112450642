// swr feat
// https://github.com/vercel/swr/pull/1450
import { useRef, useCallback } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useTranslation } from 'react-i18next';
import useSWR, { useSWRConfig } from 'swr';
import { AxiosRequestConfig } from 'axios';
import { useSetState, useMountedState, useUpdateEffect } from 'react-use';
import useEnqueueSnackbar from './useEnqueueSnackbar';
import {
	REFRESH_TOKEN_EXPIRED,
	TEMPORARY_DRIVER_TOKEN_EXPIRED,
} from '~/enums/errorMessages';
interface useSWRMutationState {
	axiosConfig: AxiosRequestConfig | null;
	data: any;
	time?: string;
	error?: Error | null;
	notification: INotification | null;
}

interface customAxiosRequestConfig extends AxiosRequestConfig {
	time?: number;
}
interface useSWRMutationOptions {
	onSuccess?: (data?: any) => any;
	onError?: (err?: Error) => any;
}

interface INotification {
	notificationOnSuccess?: boolean;
	notificationOnError?: boolean;
	successMessage?: string;
	failureMessage?: string;
}

function useSWRMutation(
	config: AxiosRequestConfig,
	options?: useSWRMutationOptions
) {
	const keyRef = useRef(uuidv4());
	const { onSuccess, onError } = options || {};
	const [state, setState] = useSetState<useSWRMutationState>({
		axiosConfig: null,
		data: null,
		error: null,
		notification: null,
	});
	const isMounted = useMountedState();
	const { i18n } = useTranslation();
	const pushSnackbar = useEnqueueSnackbar();
	const { onError: configOnError } = useSWRConfig();
	const { isValidating, mutate } = useSWR(
		state.axiosConfig
			? {
					...state.axiosConfig,
					key: keyRef.current,
			  }
			: null,
		null,
		{
			dedupingInterval: 0,
			revalidateIfStale: false,
			revalidateOnMount: false,
			onSuccess: (data) => {
				const { notificationOnSuccess = false, successMessage } =
					state.notification || {};
				if (notificationOnSuccess) {
					const message =
						successMessage ||
						i18n.t(`ajax:${state.axiosConfig?.method?.toLowerCase()}.success`);
					pushSnackbar(message, {
						variant: 'success',
					});
				}
				if (onSuccess) {
					onSuccess(data);
				}
				if (isMounted()) {
					setState({
						data,
						axiosConfig: null,
					});
				}
				return data;
			},
			onError: (error, key, config) => {
				if (
					[REFRESH_TOKEN_EXPIRED, TEMPORARY_DRIVER_TOKEN_EXPIRED].includes(
						error.message
					)
				) {
					return configOnError(error, key, config);
				}
				const { notificationOnError = false, failureMessage } =
					state.notification || {};
				if (notificationOnError) {
					const message =
						failureMessage ||
						i18n.t(`ajax:${state.axiosConfig?.method?.toLowerCase()}.error`);
					pushSnackbar(message, {
						variant: 'error',
					});
				}
				if (onError) {
					onError(error);
				}
				if (isMounted()) {
					setState({
						error,
						axiosConfig: null,
					});
				}
				return error;
			},
		}
	);
	const trigger = useCallback(
		(
			overridedConfig?: customAxiosRequestConfig,
			notification?: INotification
		) => {
			if (isMounted() && !isValidating) {
				setState({
					data: null,
					error: null,
					axiosConfig: {
						...config,
						...overridedConfig,
					},
					notification,
				});
			}
		},
		[isValidating, setState, config, isMounted]
	);

	useUpdateEffect(() => {
		if (state.axiosConfig) {
			mutate();
		}
	}, [state.axiosConfig]);
	return {
		data: state.data,
		error: state.error,
		isMutating: isValidating,
		trigger,
	};
}

export default useSWRMutation;
