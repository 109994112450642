const isLocalhost = !process.env.ENV_TYPE;
const domain = 'allytransport.com.tw';
const defaultSubdomain = 'ally';
const appSubDomain = getAppSubDomain();

export const apiURL = isLocalhost
	? 'https://api.space-dev.allytransport.com.tw/restful'
	: `https://api.${appSubDomain}.${domain}/restful`;

export const imageURL = isLocalhost
	? 'https://space-dev.allytransport.com.tw/images'
	: `${window.location.protocol}//${window.location.hostname}/images`;

export const subdomain = isLocalhost
	? defaultSubdomain
	: window.location.hostname.indexOf(appSubDomain) === 0
	? defaultSubdomain
	: window.location.hostname.split('.')[0];

export const getTemporaryDriverToken = () => {
	const params = new URLSearchParams(window.location.search);
	const token = params.get('token');
	return token;
};

function getAppSubDomain() {
	let tempSubdomain = 'driver';
	if (isLocalhost || process.env.ENV_TYPE === 'staging') {
		tempSubdomain = `${tempSubdomain}-dev`;
	}
	return tempSubdomain;
}
