import { useMemo, Fragment } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { toDateTime } from '~/utils/date';
import { IStation, IShippingHistory } from '~/types/apis/task';
import { PICKUP_STATUS, DELIVERY_STATUS } from '~/enums/status';
import Typography from './Typography';
import Box from './Box';
import Card from './Card';

interface IActive {
	active: boolean;
}

const Bullet = styled.div<IActive>`
	border-radius: 50%;
	width: 12px;
	height: 12px;
	background-color: ${({ theme, active }) =>
		active ? theme.colors.warning : theme.colors.gray};
	z-index: 1;
	position: relative;
`;

interface IConnector {
	active: boolean;
	isLast?: boolean;
	showLine: boolean;
}

const Connector = styled.div<IConnector>`
	border-left: ${({ isLast }) => (!isLast ? '4px solid' : '')};
	margin-left: -8px;
	border-color: ${({ theme, showLine }) =>
		showLine ? theme.colors.warning : theme.colors.black8};
`;

const RowConnector = styled.div<IConnector>`
	width: 50%;
	border-top: 4px solid;
	margin-left: -1px;
	margin-right: -1px;
	border-color: ${({ theme, showLine }) =>
		showLine ? theme.colors.warning : '#E0E0E0'};
`;

const Content = ({ title, name, address, createTime }: any) => {
	return (
		<Box
			direction='column'
			alignItems='flex-start'
			style={{ maxWidth: '280px' }}
		>
			<Typography variant='subtitle2'>{title}</Typography>
			<Typography>{name}</Typography>
			<Typography>{address}</Typography>
			<Typography>{createTime && toDateTime(createTime)}</Typography>
		</Box>
	);
};

const RowContent = ({ title, createTime, align }: any) => {
	return (
		<Box direction='column' alignItems={align}>
			<Typography variant='buttonM'>{title}</Typography>
			<Typography variant='label' color='black60'>
				{createTime && toDateTime(createTime)}
			</Typography>
		</Box>
	);
};

interface IStep {
	active: boolean;
	title: string;
	name?: string;
	address?: string;
	createTime?: number;
}

interface IDirectionStep {
	steps: IStep[];
}

const HorizontalContainer = styled.div`
	display: flex;
	flex-direction: column;
	height: 62px;
`;

export const HorizontalProgressBar = ({ steps }: IDirectionStep) => {
	return (
		<HorizontalContainer>
			<Box>
				{steps.map((s: IStep, index: number) => {
					const isLast = index === steps.length - 1;
					const showLine = s.active && steps[index + 1]?.active;
					return (
						<Fragment key={index}>
							<Bullet active={s.active} />
							{!isLast && (
								<RowConnector active={s.active} showLine={showLine} />
							)}
						</Fragment>
					);
				})}
			</Box>
			<Box direction='row' alignItems='flex-start'>
				{steps.map((s: IStep, index: number) => {
					const isFirst = index === 0;
					const isLast = index === steps.length - 1;
					const align = isFirst ? 'flex-start' : isLast ? 'flex-end' : 'center';
					return (
						<Box
							style={{
								width: '100%',
								paddingTop: '12px',
								paddingBottom: '20px',
							}}
							justifyContent={align}
							alignItems='center'
							key={index}
						>
							<RowContent
								title={s.title}
								createTime={s.createTime}
								align={align}
							/>
						</Box>
					);
				})}
			</Box>
		</HorizontalContainer>
	);
};

export const VerticalProgressBar = ({ steps }: IDirectionStep) => {
	return (
		<>
			{steps.map((s: IStep, index: number) => {
				const isLast = index === steps.length - 1;
				const showLine = s.active && steps[index + 1]?.active;

				return (
					<Box justifyContent='flex-start' alignItems='flex-start' key={index}>
						<Bullet active={s.active} />
						<Connector active={s.active} showLine={showLine} isLast={isLast}>
							<Box
								style={{
									paddingLeft: isLast ? '36px' : '32px',
									paddingBottom: isLast ? 0 : '20px',
								}}
							>
								<Content
									title={s.title}
									name={s.name}
									address={s.address}
									createTime={s.createTime}
								/>
							</Box>
						</Connector>
					</Box>
				);
			})}
		</>
	);
};

interface IProgressBar {
	isPickup: boolean;
	title: string;
	orientation: 'vertical' | 'horizontal';
	pickupStation?: Omit<IStation, 'id'>;
	deliverStation?: Omit<IStation, 'id'>;
	shippingHistories?: IShippingHistory[];
}

const ProgressBar = ({
	isPickup,
	title,
	orientation,
	pickupStation,
	deliverStation,
	shippingHistories,
}: IProgressBar) => {
	const { t } = useTranslation();

	const isHorizontal = orientation === 'horizontal';

	const steps = useMemo(() => {
		const PickupStatus = {
			start: PICKUP_STATUS.ASSIGNED,
			process: PICKUP_STATUS.DISPATCHED,
			complete: PICKUP_STATUS.PICK_UP_COMPLETED,
		};
		const DeliverStatus = {
			start: DELIVERY_STATUS.PICK_UP_COMPLETED,
			process: DELIVERY_STATUS.IN_TRANSIT,
			complete: DELIVERY_STATUS.DELIVERED,
		};
		const stepStatus = isPickup ? PickupStatus : DeliverStatus;
		const latestStatus: number =
			shippingHistories?.[shippingHistories?.length - 1]?.code ?? 0;

		let start: any = shippingHistories?.find(
			(s) => s.code === stepStatus.start
		);
		let process: any = shippingHistories?.find(
			(s) => s.code === stepStatus.process
		);

		let complete: any = shippingHistories?.find(
			(s) => s.code === stepStatus.complete
		);

		if (!start && latestStatus >= stepStatus.start) start = {};
		if (!process && latestStatus >= stepStatus.process) process = {};
		if (!complete && latestStatus >= stepStatus.complete) complete = {};
		return [
			{
				active: !!start,
				title: t('task:pending'),
				name: pickupStation?.name,
				address: pickupStation?.address,
				createTime: start?.createDateTime,
			},
			{
				active: !!process,
				title: t('task:processing'),
				name: deliverStation?.name,
				address: deliverStation?.address,
				createTime: process?.createDateTime,
			},
			{
				active: !!complete,
				title: t('task:completed'),
				createTime: complete?.createDateTime,
			},
		];
	}, [t, isPickup, shippingHistories, pickupStation, deliverStation]);

	return (
		<Card
			title={
				<Typography
					variant='buttonM'
					style={{ paddingBottom: title ? '20px' : '' }}
				>
					{title}
				</Typography>
			}
		>
			{isHorizontal ? (
				<HorizontalProgressBar steps={steps} />
			) : (
				<VerticalProgressBar steps={steps} />
			)}
		</Card>
	);
};

const stationDefaultValue = {
	address: '',
	contact: {
		name: '',
		phoneNumber: '',
	},
	lat: 0,
	lng: 0,
	name: '',
	note: '',
};

ProgressBar.defaultProps = {
	title: '',
	orientation: 'horizontal',
	pickupStaion: stationDefaultValue,
	deliverStaion: stationDefaultValue,
};

export default ProgressBar;
