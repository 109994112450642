import styled from 'styled-components';

const GotoTopBox = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	position: fixed;
	height: 48px;
	width: 48px;
	bottom: 20px;
	right: 20px;
	z-index: 11;
	background-color: #f5f5f5;
	border-radius: 50%;
	box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.3);
`;

export default GotoTopBox;
