import { ReactNode } from 'react';
import { formatValue } from '~/utils/format';
import Typography from './Typography';

interface LabelTextProps {
	label: string;
	text: ReactNode | string;
	style?: any;
	textStyle?: any;
}
const LabelText = ({ label, text, textStyle, style }: LabelTextProps) => {
	return (
		<div style={style}>
			<Typography
				variant='label'
				color='black60'
				style={{ paddingBottom: '4px' }}
			>
				{label}
			</Typography>
			<Typography
				variant='subtitle1'
				color='black'
				style={textStyle ? { ...textStyle } : {}}
			>
				{formatValue(text)}
			</Typography>
		</div>
	);
};

export default LabelText;
