import { ReactElement, ReactNode } from 'react';
import styled from 'styled-components';
import Box from './Box';

const Container = styled.div`
	background-color: ${({ theme }) => theme.colors.white};
	border-radius: ${({ theme }) => theme.borderRadius};
	margin-bootom: 20px;
	padding: 20px;
`;

const Divider = styled.div`
	height: 1px;
	background-color: ${({ theme }) => theme.colors.gray};
	margin-top: 8px;
	margin-bottom: 20px;
`;

interface ICard {
	title?: ReactElement;
	rightTitleElement?: ReactElement;
	hasDivider: boolean;
	children: ReactNode;
}
const Card = ({ title, rightTitleElement, hasDivider, children }: ICard) => {
	return (
		<Container>
			<Box justifyContent='space-between' alignItems='center'>
				{title}
				{rightTitleElement}
			</Box>
			{hasDivider && <Divider />}
			{children}
		</Container>
	);
};

Card.defaultProps = {
	hasDivider: false,
};
export default Card;
