import { useMemo } from 'react';
import type { ITimeWindow } from '~/types/api';
import { formatValue } from '~/utils/format';
import { secondsToHourMinutesText, toDate } from '~/utils/date';

const useTimeWindow = (
	timeWindow: ITimeWindow | null,
	showDate: boolean = true
) => {
	const timeContent = useMemo(() => {
		if (!timeWindow) return '';
		const date = timeWindow?.date && toDate(Number(timeWindow.date));
		return `${showDate ? formatValue(date) : ''} ${secondsToHourMinutesText(
			timeWindow?.start
		)} - ${secondsToHourMinutesText(timeWindow?.end)}`;
	}, [timeWindow, showDate]);
	return timeContent;
};

export default useTimeWindow;
