import { DefaultTheme as styledDefaultTheme } from 'styled-components';
import * as palette from './colors';

const defaultTheme: styledDefaultTheme = {
	borderRadius: '8px',
	bg: palette.BACKGROUND,
	colors: {
		primary: palette.BLACK,
		secondary: palette.BLACK,
		error: palette.ERROR,
		warning: palette.WARNING,
		info: palette.INFO,
		success: palette.SUCCESS,
		divider: palette.DIVIDER,
		white: palette.WHITE,
		gray: palette.GRAY,
		...palette.COLOR_WITH_OPACITY,
	},
	styles: {
		root: {
			fontFamily: 'PingFang TC',
			fontStyle: 'normal',
			color: palette.BLACK,
		},
		h1: {
			fontWeight: 600,
			fontSize: '96px',
			lineHeight: '134px',
		},
		h2: {
			fontWeight: 600,
			fontSize: '60px',
			lineHeight: '84px',
		},
		h3: {
			fontWeight: 600,
			fontSize: '48px',
			lineHeight: '67px',
		},
		h4: {
			fontWeight: 600,
			fontSize: '34px',
			lineHeight: '48px',
		},
		h5: {
			fontWeight: 600,
			fontSize: '24px',
			lineHeight: '34px',
		},
		h6: {
			fontWeight: 600,
			fontSize: '20px',
			lineHeight: '28px',
		},
		subtitle1: {
			fontWeight: 500,
			fontSize: '16px',
			lineHeight: '22.4px',
		},
		subtitle2: {
			fontWeight: 500,
			fontSize: '14px',
			lineHeight: '22.4px',
		},
		body1: {
			fontWeight: 400,
			fontSize: '16px',
			lineHeight: '22.4px',
		},
		body2: {
			fontWeight: 400,
			fontSize: '14px',
			lineHeight: '20px',
		},
		helper: {
			fontWeight: 400,
			fontSize: '12px',
			lineHeight: '17px',
		},
		buttonM: {
			fontWeight: 500,
			fontSize: '14px',
			lineHeight: '19.6px',
		},
		buttonL: {
			fontWeight: 500,
			fontSize: '16px',
			lineHeight: '22.4px',
		},
		inputText: {
			fontWeight: 400,
			fontSize: '16px',
			lineHeight: '22.4px',
		},
		label: {
			fontWeight: 400,
			fontSize: '12px',
			lineHeight: '14px',
		},
		header: {
			fontWeight: 500,
			fontSize: '18px',
			lineHeight: '23.8px',
		},
	},
};

export { defaultTheme };
