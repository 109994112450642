import styled from 'styled-components';
import { keyframes } from 'styled-components';

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

interface IIcon {
	width: string;
	height: string;
	url: any;
	style?: React.CSSProperties;
	onClick?: any;
	isLoading?: boolean;
}
interface IStyledIcon {
	width: string;
	height: string;
	isLoading?: boolean;
}

const StyledIcon = styled.img<IStyledIcon>`
	width: ${({ width }) => `${width}`};
	height: ${({ height }) => `${height}`};
	animation: ${({ isLoading }) => (isLoading ? rotate : '')} 0.3s linear
		infinite;
`;

const Icon = ({ url, isLoading = false, ...props }: IIcon) => {
	return <StyledIcon src={url.default} isLoading={isLoading} {...props} />;
};

Icon.defaultProps = {
	width: '24px',
	height: '24px',
};
export default Icon;
