import { ComponentPropsWithoutRef, ReactNode } from 'react';
import styled, { keyframes } from 'styled-components';

interface IButton {
	variant: string;
	size: string;
	disabledColor?: string;
}

interface ILoadingAnimate {
	variant: string;
}

export interface ButtonProps extends ComponentPropsWithoutRef<'button'> {
	variant?: 'outlined' | 'contained';
	size?: 'lg' | 'md' | 'xs';
	loading?: boolean;
	children: ReactNode;
	startAdornment?: React.ReactNode;
	endAdornment?: React.ReactNode;
	disabledColor?: string;
}

const StyledButton = styled.button<IButton>`
	width: 100%;
	height: ${({ size }) => (size === 'lg' ? '56px' : '40px')};
	padding: 10px;
	font-size: ${({ size }) => (size === 'xs' ? '14px' : '16px')};
	font-weight: 500;
	line-height: 22px;
	cursor: pointer;
	border-radius: 8px;
	border: ${({ theme }) => `1px solid ${theme.colors.primary}`};
	display: flex;
	align-items: center;
	justify-content: center;

	color: ${({ variant, theme }) =>
		variant === 'contained' ? theme.colors.white : theme.colors.primary};

	background-color: ${({ variant, theme }) =>
		variant === 'contained' ? theme.colors.primary : theme.colors.white};

	:disabled {
		pointer-events: none;
		border: none;
		color: ${({ theme }) => theme.colors.white};
		background-color: ${({ theme }) => theme.colors.black20};
		${({ theme, disabledColor }) =>
			`background-color: ${disabledColor && theme.colors['error']}`}
	}
	&.blocked {
		background-color: ${({ theme }) => theme.colors.black60};
	}
`;

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const LoadingAnimate = styled.div<ILoadingAnimate>`
	border: 4px solid;
	border-color: white;
	border-top-color: transparent;
	border-radius: 50%;
	padding: 16px;
	animation: ${rotate} 2s linear infinite;
`;

const Button = ({
	loading = false,
	children,
	startAdornment,
	endAdornment,
	variant = 'contained',
	size = 'lg',
	...props
}: ButtonProps) => {
	return (
		<StyledButton variant={variant} size={size} {...props}>
			{startAdornment}
			{loading ? <LoadingAnimate variant={variant} /> : children}
			{endAdornment}
		</StyledButton>
	);
};

export default Button;
