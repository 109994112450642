import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Typography, Box, Icon } from '~/components';
import * as ErrorIcon from '~/static/icons/EmptyList.png';

const Container = styled(Box)`
	${() => `height: ${window.innerHeight - 56}px;`}
	background-color: ${({ theme }) => theme.colors.bg};
	flex-direction: column;
`;

const EmptyListView = () => {
	const { t } = useTranslation();
	return (
		<Container>
			<Icon url={ErrorIcon} width='64px' height='64px' />
			<Typography variant='h6' style={{ marginTop: '32px' }}>
				{t('task:emptyListTip')}
			</Typography>
		</Container>
	);
};

export default EmptyListView;
