import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { formatWithComma, formatValue } from '~/utils/format';
import { Box, LabelText } from '~/components';

const InfoBox = ({ containerInfo }: any) => {
	const { t } = useTranslation();

	return (
		<Container>
			<LabelText
				label={t('task:containerType')}
				text={formatValue(containerInfo.type, () =>
					t(`task:containerType.${containerInfo.type}`)
				)}
			/>
			<LabelText
				label={t('task:containerSize')}
				text={formatValue(containerInfo.size, () =>
					t(`task:containerSize.${containerInfo.size}`)
				)}
			/>
			<LabelText
				label={t('task:containerChassisType')}
				text={formatValue(containerInfo.chassis, () =>
					t(`task:containerChassisType.${containerInfo.chassis}`)
				)}
			/>
			<LabelText
				label={t('task:containerWeight')}
				text={formatWithComma(containerInfo.weight)}
			/>
		</Container>
	);
};

const Container = styled(Box)`
	width: 100%;
	justify-content: space-between;
`;

export default InfoBox;
