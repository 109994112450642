import { ComponentPropsWithoutRef } from 'react';
import styled from 'styled-components';

interface IBox extends ComponentPropsWithoutRef<'div'> {
	direction: string;
	alignItems: string;
	justifyContent: string;
}

const StyledDiv = styled.div<IBox>`
	display: flex;
	align-items: ${({ alignItems }) => alignItems};
	justify-content: ${({ justifyContent }) => justifyContent};
	flex-direction: ${({ direction }) => direction};
`;

const Box = ({ direction, ...props }: IBox) => {
	return <StyledDiv direction={direction} {...props} />;
};

Box.defaultProps = {
	direction: 'row',
	alignItems: 'center',
	justifyContent: 'center',
};
export default Box;
