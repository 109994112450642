import { lazy } from 'react';

const PalletTaskDetailPage = lazy(
	() =>
		import('./pallet/TaskDetailPage' /* webpackChunkName: 'TaskDetailPage' */)
);

const MultistopTaskDetailPage = lazy(
	() =>
		import(
			'./multistop/TaskDetailPage' /* webpackChunkName: 'TaskDetailPage' */
		)
);

const ContainerTaskDetailPage = lazy(
	() =>
		import(
			'./container/TaskDetailPage' /* webpackChunkName: 'TaskDetailPage' */
		)
);

export {
	PalletTaskDetailPage,
	MultistopTaskDetailPage,
	ContainerTaskDetailPage,
};
