import { NODATA_TEXT } from '~/enums/view';

export const formatValue = (value: any, callback?: Function) => {
	if (value) {
		if (callback) {
			return callback(value);
		} else {
			return value;
		}
	}
	return NODATA_TEXT;
};

export const formatWithComma = (num?: number) =>
	num ? String(num).replace(/(\d{1,3})(?=(\d{3})+$)/g, '$&,') : NODATA_TEXT;

export const groupBy = <QueryKey extends string, ResponseType>(
	arr: any[],
	key: string
): { [key in QueryKey]: ResponseType } => {
	return arr.reduce((groups: any, item: any) => {
		const val = item[key];
		groups[val] = groups[val] || [];
		groups[val].push(item);
		return groups;
	}, {});
};
